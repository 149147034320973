import { DesignHome, ProjectInfo } from 'model/designHome';
import DesignHomeTypes from './designHomeTypes';
import { Auth } from "model/auth.model";
import { FinancialPackage } from 'model/financialPlan';
import { FilterRoomType } from 'constants/homeConstants';
import { ECurrency } from 'constants/currency';
import { TextContentBrand } from 'model/textContent';
import { getStorage } from 'services/storageService';
import { StorageKey } from 'constants/storage';

export enum BudgetValue {
  minBudget = 34000,
  maxBudget = 320000,
}

export type FilterValue = {
  viewRating: number;
  minBudget: number;
  maxBudget: number;
  noOfBedrooms: FilterRoomType;
  currency: ECurrency;
};

export type TDesignHomeState = {
  designHome: DesignHome;
  auth: Auth;
  financePlan: FinancialPackage,
  projectInfo: ProjectInfo,
  filterProjects: FilterValue,
  textContentBrand: TextContentBrand,
};

const initialData: TDesignHomeState = {
  designHome: new DesignHome({}),
  auth: new Auth({}),
  financePlan: new FinancialPackage({}),
  projectInfo: new ProjectInfo({}),
  filterProjects: {
    minBudget: BudgetValue.minBudget,
    maxBudget: BudgetValue.maxBudget,
    viewRating: 3,
    noOfBedrooms: FilterRoomType.OneBedroom,
    currency: ECurrency.Usd,
  },
  textContentBrand: new TextContentBrand({}),
};

const DesignHomeReducer = (
  state: TDesignHomeState = initialData,
  actions: any
): TDesignHomeState => {
  const { data } = actions;
  switch (actions.type) {
    case DesignHomeTypes.SetDesignHome:
      return {
        ...state,
        designHome: { ...state.designHome, ...data },
      };
    case DesignHomeTypes.ResetDesignHome:
      return {
        ...state,
        designHome: new DesignHome({})
      }

    case DesignHomeTypes.SetAuth:
      return {
        ...state,
        auth: { ...state.auth, ...data },
      };

    case DesignHomeTypes.SetFinancePlan:
      return {
        ...state,
        financePlan: { ...state.financePlan, ...data },
      };
    case DesignHomeTypes.ResetFinancePlan:
      return {
        ...state,
        financePlan: new FinancialPackage({})
      }
    case DesignHomeTypes.FilterProjects:
      return {
        ...state,
        filterProjects: { ...state.filterProjects, ...data },
      }

    case DesignHomeTypes.SetTextContentBrand:
      return {
        ...state,
        textContentBrand: { ...state.textContentBrand, ...data },
      }

    default:
      return state;
  }
};

export default DesignHomeReducer;
