import { ITelephoneLatestResult } from 'model/userManagement.model';
import {
  saleAgentAuthRequest,
  homebuyerAuthRequest,
  userManagementRequest,
  portalUserRequest,
  saleAgentManagementRequest,
  projectManagementRequest,
  userPublicRequest,
  userPrivateRequest,
  pmsPublicRequest,
} from './requests';
import { V2ItemResponsePayload } from 'constants/requestConstants';
import { PersonalInfo } from 'pages/MyProfile/PersonalProfile';

export type FloorSegment = {
  segmentId?: number;
  floorId?: number;
  status?: string;
}

export type FetchPersonProfile = {
  userId?: number;
} & PersonalInfo

export type ContactForm = {
  name: string;
  phone: string;
  email: string;
  title: string;
  message: string;
}

export const homebuyerLoginApi = async () => {
  return homebuyerAuthRequest.post(`/homebuyer/login`);
};

export const loginWithoutAgent = async () => {
  return portalUserRequest.post(`/public/homebuyer/login`);
};

export const saleAgentLoginApi = async (loginPayload: any): Promise<V2ItemResponsePayload<string>> => {
  return saleAgentAuthRequest.post(`/public/saleagent/login`, loginPayload);
};

export const getMeInfoPortalApi = async (): Promise<any> => {
  return portalUserRequest.get('/homebuyer/me');
};

export const fetchSaleAgentInfoApi = async (): Promise<any> => {
  return saleAgentManagementRequest.get('/auth/me');
};

export const getMeInfoApi = async (): Promise<any> => {
  return userManagementRequest.get('/homebuyer/me');
};

export const completeProfileApi = async (payload: any) => {
  return userManagementRequest.post(`/homebuyer/complete-profile`, payload);
};

export const updateProfileApi = async (payload: any) => {
  return userManagementRequest.put(`/homebuyer/me`, payload);
};

export const checkAvailableFloorSegment = async (params: FloorSegment) => {
  return projectManagementRequest.get('/segment/check-validate', { params });
};

export const getLatestResultByTelephoneApi = async (
  phone: any
): Promise<ITelephoneLatestResult> => {
  return userManagementRequest.get(`module-result/telephone/${phone}/latest`);
};

export const saveResultToServerApi = async (payload: any) => {
  return userManagementRequest.post(`module-result/save/`, payload);
};

export const getUserByOrder = async (orderId: number): Promise<V2ItemResponsePayload<string>> => {
  return saleAgentManagementRequest.get(`order/login-by-order/${orderId}`);
};

export const signupLightEmail = async (payload: {uuid: string, username: string, email: string}) => {
  return userPublicRequest.post(`/email/register`, payload);
};

export const signinLightEmail = async (payload: {uuid: string, email: string}) => {
  return userPublicRequest.post(`/email/login`, payload);
};

export const checkExistPhoneNumber = async (payload: {phoneNumber: string}) => {
  return userPublicRequest.post(`/check-phone-number`, payload);
};

export const signupLightVerifyEmail = async (payload: {email: string, verifyCode: string}) => {
  return userPublicRequest.post(`/verify/email`, payload);
};

export const signupLightByPhoneNumber = async (payload: {uuid: string, username: string, firebaseToken: string}) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Firebase-Token' : payload?.firebaseToken
      // Add any other headers you need
    },
  };

  return userPublicRequest.post(`/phone/register`, { uuid: payload?.uuid, username: payload?.username }, config);
};

export const signinLightByPhoneNumber = async (payload: {uuid: string, firebaseToken: string}) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Firebase-Token' : payload?.firebaseToken
      // Add any other headers you need
    },
  };

  return userPublicRequest.post(`/phone/login`, { uuid: payload?.uuid }, config);
};

// GET Person Profile
export const fetchPersonProfileByIdApi = async () => {
  return userPrivateRequest.get(`/user`);
}

export const updatePersonProfileByIdApi = async ({
  ...params
}: FetchPersonProfile) => {
  return userPrivateRequest.put(`/user`, params);
}

export const updateUserEmailApi = async (payload: {email: string}) => {
  return userPrivateRequest.post(`/user/update-mail`, payload);
}

export const updateUserPhoneNumberApi = async (payload: {telephone: string, firebaseToken: string}) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Firebase-Token' : payload?.firebaseToken
      // Add any other headers you need
    },
  };
  return userPrivateRequest.post(`/user/update-phone`, payload, config);
}

export const verifyEmailApi = async (payload: {email: string, verifyCode: string}) => {
  return userPrivateRequest.post(`/user/verify-mail`, payload);
}

export const sendContactUsApi = async (payload: ContactForm) => 
  pmsPublicRequest.post(`/contact`, payload);