import React from 'react';
import DollarOutlinedIcon from 'components/sgvIcons/DollarOutlined';
import VndOutlined from 'components/sgvIcons/VndOutlined';

export enum ECurrency {
  Vnd = 'VND',
  Usd = 'USD',
}

export enum ELocales {
  vi = 'vi',
  en = 'en'
}

export enum ELanguageLocales {
  vi = 'vi',
  en = 'en',
  zh = 'zh',
  fr = 'fr',
  ms = 'ms',
  pl = 'pl',
  ar = 'ar',
  pt = 'pt',
  es = 'es',
  de = 'de',
  th = 'th',
  in = 'in',
  nl = 'nl',
  fil = 'fil',
  el = 'el',
  ja = 'ja',
  ko = 'ko',
  tl = 'tl',
}

export const LIST_CURRENCY: TCurrencyObject[] = [
  {
    id: 1,
    code: ECurrency.Usd,
    conversionRate: 1,
    isDefault: true,
    name: "US DOLLAR",
    state: 1,
    symbol: "$",
    icon: <DollarOutlinedIcon width="16px" height="16px" />,
  },
  {
    id: 2,
    code: ECurrency.Vnd,
    conversionRate: 23400,
    isDefault: false,
    name: "vietnamdong",
    state: 1,
    symbol: "đ",
    icon: <VndOutlined width="16px" height="16px" />,
  },
];

export type TCurrencyObject = {
  code: ECurrency;
  conversionRate: number;
  id: number;
  state: number;
  symbol: string;
  name: string;
  isDefault: boolean;
  icon?: React.ReactElement;
};

export const CURRENCY_LOCALE_CODES = {
  [ELocales.en]: 'en-US',
  [ELocales.vi]: 'vi-VN',
};
