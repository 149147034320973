import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';

// ICONS
import ClearIcon from '@mui/icons-material/Clear';

// MODELS

// HOOKS
import { useDialog } from 'hooks';
import { useSelector } from 'react-redux';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';

// COMPONENT
import { Stack } from '@mui/material';
const Dialog = loadable(() => import('components/modals/Dialog'));
const SignupVerifyOtp = loadable(() => import('./commons/SignupVerifyOtp'));
const SignupLightForm = loadable(() => import('./sections/SignupLightForm'));

export enum TypeSinup {
  email = 'email',
  PhoneNumber = 'phoneNumber',
}

export enum TypeSignInAndSignUp {
  SignIn = 'SignIn',
  SignUp = 'SignUp'
}

type SignupLightProps = {
  show: boolean,
  typePage?: string,
  onClose: () => void;
};

enum StepSignup {
  FormSignup = 'FormSignup',
  Otp = 'otp'
}

function SignupLight(props: SignupLightProps) {
  const { show, onClose, typePage = TypeSignInAndSignUp.SignUp } = props;
  const { closeDialog, dialogProps, openDialog } = useDialog();
  const [stepSignup, setStepSignup] = useState(StepSignup.FormSignup);
  const [typeSignup, setTypeSignup] = useState<string>(TypeSinup.email);
  const [dataPhoneNumber, setDataPhoneNumber] = useState<{ name?: string; phoneNumber?: string, prefix?: string, email?: string }>({
    email: '',
    name: '',
    phoneNumber: '',
    prefix: '',
  });
  const { auth: { tokenPMS } } = useSelector(selectDesignHome)

  useEffect(() => {
    setStepSignup(StepSignup.FormSignup);
  }, [tokenPMS])

  useEffect(() => {
    if (show) {
      openDialog();
    } else {
      closeDialog();
    }
  }, [show])

  useEffect(() => {
    if (localStorage.getItem("PREV_PATH") !== "") {
      const PREV_PATH = localStorage.getItem("PREV_PATH")?.split("_") || [];
      if (PREV_PATH.length === 2) {
        if (PREV_PATH[1] === "REDIRECT") {
          localStorage.setItem("PREV_PATH", PREV_PATH[0] + "_REDIRECT_ED");
        }
      } else if (PREV_PATH.length === 3) {
        localStorage.removeItem("PREV_PATH")
      }
      
    }
  }, [])

  return (
    <CustomDialog
      {...dialogProps}
      fullWidth
      maxWidth="xs"
      open={show}
      onClose={onClose}
    >
      <Stack flexDirection="row" justifyContent="flex-end" mb={2}>
        <div className="button-close" onClick={onClose}>
          <ClearIcon fontSize='medium' style={{ cursor: 'pointer', color: '#fff' }} />
        </div>
      </Stack>
      {
        stepSignup === StepSignup.FormSignup &&
        <SignupLightForm
          onSubmitFormPhoneNumber={data => {
            setDataPhoneNumber(data);
          }}
          typePage={typePage}
          typeVerify={typeSignup}
          onChangeTypeVerify={(value) => setTypeSignup(value)}
          onChangeStep={(typeSignup) => {
            setTypeSignup(typeSignup);
            setStepSignup(StepSignup.Otp);
          }} />
      }
      {
        stepSignup === StepSignup.Otp &&
        <SignupVerifyOtp
          onClose={onClose}
          typePage={typePage}
          dataForm={dataPhoneNumber}
          typeVerify={typeSignup}
          onChangeTypeSignup={() => {
            setStepSignup(StepSignup.FormSignup);
            setTypeSignup(typeSignup);
          }}
        />
      }
    </CustomDialog>
  );
}

export default SignupLight;

export const CustomDialog = styled(Dialog)`
.MuiDialogContent-root{
  padding: 25px 38px;
  padding-top: 25px !important;
}

.opt-input-wrapper{
  & > div{
    justify-content: space-between;
    gap: 0.5rem;
  }
  .inputOtp{
    margin:0px !important;
    width: 100% !important;
    height: 40px !important;
  }
}

.button-close{
  position: absolute;
  top: 15px;
  right: 15px;
}
  .title{
    font-size: 24px;
  }
  .switch-label{
    font-size: 14px;
  }
  .description{
    font-size: 13px;
    color: #C9C8CC;
  }
  .MuiFormControl-root{
    width: 100%;

    fieldset{
      border: 1px solid ${p => p.theme.palette.primary.contrastText} !important;
    }
  }
  /* .MuiSwitch-sizeMedium{
    padding: 0px
  } */

  .back{
    background-color: ${p => p.theme.palette.accent.main};
    color: #fff;
    outline: none;
    text-transform: capitalize;
    width: 100%;
    height: 40px;
    &.back{
      background-color: #63606F;
    }

    &:hover{
      background-color: #BF9EBA;
    }
  }
  .join{
    background-color: ${p => p.theme.palette.accent.main};
    color: #fff;
    outline: none;
    text-transform: capitalize;
    width: 100%;
    height: 40px;

    &.back{
      background-color: #63606F;
    }

    &:hover{
      background-color: #BF9EBA;
    }
  }
  button{
    
  }

  .MuiOutlinedInput-root{
    padding: 0px !important;
  }
  .MuiAutocomplete-root{
    width: auto !important;
  }

  .opt-input-wrapper {
    & > div > div:focus-visible {
      outline: none;
    }

    .inputStyle {
      width: 100% !important;
      height: 40px !important;
      margin-right: 0;
      border-radius: 0;
      background-color: ${p => p.theme.palette.primary.dark};
      border: 1px solid ${p => p.theme.palette.primary.contrastText};
      color: #fff;
    }
  }

  .MuiSwitch-root{
    /* height: 25px;
    padding: 0px !important; */
    padding-left: 0;
    padding-right: 15px;
    padding-top: 0;
    height: 25px;
    padding-bottom: 0;
  }
  .MuiSwitch-thumb{
    width: 15px !important;
    height: 15px !important;
  }
  .MuiSwitch-switchBase{
    padding-top: 2px;
    padding-bottom: 2px;
    height: 25px;
    padding-left: 3px;
  }

  .MuiSwitch-thumb{
    background-color: #fff;
  }
  .MuiSwitch-switchBase.Mui-checked ~ .MuiSwitch-track{
    background-color: ${p => p.theme.palette.accent.main};
  }

  .MuiAutocomplete-root{
    .MuiOutlinedInput-input{
      padding-left: 2px !important;
    }
    .MuiInputAdornment-root {
      padding-left: 12px;
    }
    .MuiAutocomplete-clearIndicator {
      display: none !important;
    }
  }
  .MuiOutlinedInput-root{


    &.Mui-focused{
      
    }
    
  } 


  .MuiAlert-message{
    display: flex !important;
    align-items: center !important;
  }

  .MuiOutlinedInput-root{
    width: 100%;
    height: 40px;

    fieldset{
      border: 1px solid ${p => p.theme.palette.primary.contrastText} !important;
    }
  }

  .MuiSwitch-track{
    border-radius: 11px !important;
  }
  .MuiSelect-select{
    padding: 0 10px !important;
  }

  .resend-button{
    cursor: pointer;

    &:hover{
      text-decoration: underline;
      color: #BF9EBA;
    }
  }

  /* .opt-input-wrapper > div div{
    width: 30px !important;
    height: 30px !important;
  } */

`;