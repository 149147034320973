import { i18n } from 'locales';
import { ChipProps } from '@mui/material/Chip';
import { OrderStatus } from 'model/order';

export const tOrderStatusLabels = (): Record<OrderStatus, string> => ({
  [OrderStatus.New]: i18n.t('Draft'),
  [OrderStatus.Pending]: i18n.t('Reserved'),
  [OrderStatus.PreOrdered]: i18n.t('Pre-ordered'),
  [OrderStatus.Booked]: i18n.t('DA'),
  [OrderStatus.Bought]: i18n.t('SPA'),
  [OrderStatus.Cancelled]: i18n.t('Cancelled'),
  [OrderStatus.Any]: i18n.t('Any'),
});

export const ORDER_STATUS_COLORS: Record<OrderStatus, ChipProps['color']> = {
  [OrderStatus.New]: 'warning',
  [OrderStatus.Pending]: 'warning',
  [OrderStatus.PreOrdered]: 'warning',
  [OrderStatus.Booked]: 'success',
  [OrderStatus.Bought]: 'success',
  [OrderStatus.Cancelled]: 'default',
  [OrderStatus.Any]: 'default',
}

export enum OrderType {
  FINANCIAL_PACKAGE_AND_PERSONALISATION = 'FINANCIAL_PACKAGE_AND_PERSONALISATION',
  PERSONALISATION = 'PERSONALISATION',
  FINANCIAL_PACKAGE = 'FINANCIAL_PACKAGE',
  NORMAL = 'NORMAL',
  BARESHELL_AND_FINANCIAL_PACKAGE = 'BARESHELL_AND_FINANCIAL_PACKAGE',
  BARESHELL = 'BARESHELL',
  WAITTING_LIST_PERSONALISATION = 'WAITTING_LIST_PERSONALISATION', // 3D
  WAITING_LIST = 'WAITING_LIST', //2D
  WAITING_LIST_BARESHELL = 'WAITING_LIST_BARESHELL' // Bareshell
}