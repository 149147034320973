import { useEffect, DependencyList } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// ACTIONS
import { setLayout } from 'store/global/globalActions';

// CONSTANTS
import { LayoutState } from 'store/global/globalReducers';

const useLayout = (layout: LayoutState | (() => LayoutState), dependencies: DependencyList = []) => {
  const dispatch = useDispatch();
  const { i18n: { language } } = useTranslation();
  
  useEffect(() => {
    const nextLayout = typeof layout === 'function' ? layout() : layout;
    
    dispatch(setLayout(nextLayout));
    // eslint-disable-next-line
  }, [language, ...dependencies]);
}

export default useLayout;
