import React, { PropsWithChildren, useEffect } from 'react';
import styled from 'styled-components';

// COMPONENTS
import MainPageHeader from './headers/MainPageHeader';

// CONTEXTS
import { fetchMyOrderApi } from 'apis/orderApis';
import { OrderType } from 'constants/orderConstants';
import { PageRouteName, PageRouteNameOrderedValidation } from 'constants/route';
import { SessionKey, StorageKey } from 'constants/storage';
import { useLayout } from 'contexts/layoutContext';
import { useOrderContext } from 'contexts/ordersContext';
import { usePriceContext } from 'contexts/priceContext';
import { useRoute } from 'hooks';
import Order, { OrderStatus, OrderStep } from 'model/order';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, removeStorage, setSession, setStorage } from 'services/storageService';
import { setAuth, setDesignHome, setFinancialPlan } from 'store/designHome/designHomeActions';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';
import { checkFlow3D } from 'utils/dataHelpers';
import { v4 as uuidV4 } from "uuid";
import { fetchPhonePrefixApi } from 'apis/projectApis';

const MainLayout = ({ children }: PropsWithChildren<{}>) => {
  const { headerFloating, headerTransparent, isFloating } = useLayout();
  const dispatch = useDispatch();
  const { pathname, redirect } = useRoute();

  const { preferCurrency } = usePriceContext();
  const { updateOrders } = useOrderContext();

  const {
    designHome: { orderStatus, orderType, staticDate },
    auth: { tokenPMS, tokenUMS }
  } = useSelector(selectDesignHome);

  const loadMyOrders = async () => {
    if (tokenPMS && tokenUMS) {
      await fetchMyOrderApi({ currencyCode: preferCurrency }).then(({ data }) => {
        const newMyOrders = (data || []).map((item: Order) => new Order().fromPayload(item));
        updateOrders(newMyOrders);
      });
    }
  }

  // Phone Prefix
  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchPhonePrefixApi();
        setSession(SessionKey.PhonePrefix, data?.prefix);
      } catch (error) {
        console.log('Cannot Get Phone Prefix');
      }
    })();
  }, [])

  useEffect(() => {
    if (getStorage(StorageKey.StepYourDesign)) {
      dispatch(setDesignHome({ ...getStorage(StorageKey.StepYourDesign) }));
    }
    if (getStorage(StorageKey.AuthDesign)) {
      const authDesign = getStorage(StorageKey.AuthDesign);
      //check expiredTime and remove storage
      console.debug("expiredTime: ", authDesign?.expiredTime, moment(new Date(authDesign?.expiredTime)), moment(), moment(authDesign?.expiredTime, 'DD/MM/YYYY HH:MM:SS').isBefore(moment()))
      if (authDesign?.expiredTime && moment(new Date(authDesign?.expiredTime)).isBefore(moment())) {
        removeStorage(StorageKey.AuthDesign);
      } else {
        dispatch(setAuth({ ...authDesign }));
      }
    }
    if (getStorage(StorageKey.OrderTemp)) {
      dispatch(setFinancialPlan({ ...getStorage(StorageKey.OrderTemp)?.financialPackage }));
    } else {
      if (getStorage(StorageKey.FinancialPackage)) {
        dispatch(setFinancialPlan({ ...getStorage(StorageKey.FinancialPackage) }));
      }
    }

    let uuid = getStorage(StorageKey.UUID_Design);
    if (!uuid) {
      uuid = uuidV4();
      setStorage(StorageKey.UUID_Design, uuid);
    }
  }, [])

  // Load My Orders
  useEffect(() => {
    loadMyOrders();
  }, [tokenPMS, tokenUMS])

  useEffect(() => {
    if (pathname.includes(PageRouteName.ChooseSegment) || pathname.includes(PageRouteName.ChooseBlock)) {
      const newData = { layoutId: undefined, productItems: undefined, personalityProperties: undefined };
      dispatch(setDesignHome({ ...newData }));
      setStorage(StorageKey.StepYourDesign, { ...getStorage(StorageKey.StepYourDesign), ...newData });
    }

    if (PageRouteNameOrderedValidation.some(item => pathname.includes(item)) &&
      (getStorage(StorageKey.StepOrder) === OrderStep.Ordered ||
        orderStatus === OrderStatus.Booked ||
        orderStatus === OrderStatus.Bought ||
        orderStatus === OrderStatus.Cancelled ||
        orderStatus === OrderStatus.Pending ||
        orderStatus === OrderStatus.PreOrdered)) {
      if (!checkFlow3D(orderType!)) {
        redirect('/');
      } else {
        if (staticDate) {
          if (moment() > moment(staticDate)) {
            redirect('/');
          }
        } else {
          if (orderType! === OrderType.WAITTING_LIST_PERSONALISATION) return;
          redirect('/');
        }
      }
    }

    if (
      pathname.includes(PageRouteName.YourDesign) ||
      pathname.includes(PageRouteName.ChooseCollection) ||
      pathname.includes(PageRouteName.SignatureLayout)
    ) {
      const blockId = getStorage(StorageKey.StepYourDesign)?.blockId || '';
      const floorId = getStorage(StorageKey.StepYourDesign)?.floorId || '';
      const planId = getStorage(StorageKey.StepYourDesign)?.planId || '';
      const status = getStorage(StorageKey.StepYourDesign)?.status || '';
      const segmentId = getStorage(StorageKey.StepYourDesign)?.segmentId || '';
      if (!blockId || !floorId || !planId || !status || !segmentId) {
        redirect('/');
      }
    }
  }, [pathname])

  return (
    <Template>
      <MainPageHeader
        floating={headerFloating ? headerFloating : isFloating}
        transparent={isFloating ? false : headerTransparent}
      />
      <main>{children}</main>
    </Template>
  );
};

const Template = styled.div`
  // width: 100vw;
  // display: grid;
  // grid-template-rows: auto 1fr;

  background: ${p => p.theme?.palette?.primary?.dark};
  // height: 100%;
`;

export default MainLayout;
