import Types from './globalTypes'

import { LayoutState } from './globalReducers';

export const setLayout = (params: LayoutState) => ({
  type: Types.SetLayout,
  ...params,
});

export const setDesignChanged = (data: boolean) => {
  return {
    type: Types.SetDesignChanged,
    data
  }
}
