import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Fade from '@mui/material/Fade';
import progressIcon from 'assets/gif/process-icon.webp';

type Props = {
  loading?: boolean,
  noRender?: boolean,
  noInitRender?: boolean,
  children?: any,
}

type StyleProps = {
  fullScreen?: boolean,
  minHeight?: number | string,
}

const IconProgress = ({
  loading = true,
  noRender = false,
  noInitRender = false,
  children,
  ...styleProps
}: Props & StyleProps) => {
  const [show, setShow] = useState(!noInitRender);
  
  useEffect(() => {
    if (loading) {
      if (noRender) setShow(false);
    } else {
      if (noRender || noInitRender) setShow(true);
    }
  }, [loading]);
  
  return (
    <Section {...styleProps}>
      {show && children}
      <Fade
        in={loading}
        // style={{ transitionDelay: loading ? '800ms' : '0ms' }}
        unmountOnExit
      >
        <div className="loading-indicator" />
      </Fade>
    </Section>
  );
};

const Section = styled.section<StyleProps>`
  width: 100%;
  height: 100%;
  ${p => p.minHeight && css`
    min-height: ${p.theme.func.length(p.minHeight)};
  `}
  position: relative;
  
  .loading-indicator {
    width: 100%;
    height: 100%;
    position: ${p => p.fullScreen ? 'fixed' : 'absolute'};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("${progressIcon}") center center no-repeat, rgba(0, 0, 0, 0.5);
    background-size: 10rem;
    z-index: 1000;
  }
`;

export default IconProgress;
