import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

// SERVICES
import { getStorage } from 'services/storageService';

// CONSTANTS
import { PageRouteName } from 'constants/route';
import { StorageKey } from 'constants/storage';

type LayoutState = {
  headerTransparent: boolean;
  isNavbarHide: boolean;
  isFloating: boolean;
  isActionButtonHide: boolean;
  paddingTopHeaderFixed: number;
};

const initLayoutState: LayoutState = {
  headerTransparent: false,
  isNavbarHide: false,
  isFloating: false,
  isActionButtonHide: false,
  paddingTopHeaderFixed: 0,
};

type LayoutMemo = {
  headerFloating: boolean;
  isNavbarProfile: boolean;
};

const defaultLayoutMemo: LayoutMemo = {
  headerFloating: false,
  isNavbarProfile: false,
};

type LayoutActions = {
  setState: (newState: LayoutState) => void;
  updateState: (updatedState: Partial<LayoutState>) => void;
};

const defaultLayoutActions: LayoutActions = {
  setState: () => {},
  updateState: () => {},
};

const LayoutContext = createContext<LayoutState & LayoutMemo & LayoutActions>({
  ...initLayoutState,
  ...defaultLayoutMemo,
  ...defaultLayoutActions,
});

export const LayoutProvider = ({ children }: PropsWithChildren<{}>) => {
  const location = useLocation();
 
  const isHeaderFloating = useMemo(() => {
    return Object.values(PageRouteName).some(item => item.includes(location.pathname));
  }, [location]);

  const isActiveProfile = useMemo(() => {
    return [
      ...[PageRouteName.PersonalProfile],
      ...[PageRouteName.MyOrderConfirm]
    ].some(item => location.pathname.includes(item));
  }, [location])

  const [state, setState] = useState<LayoutState>(() => ({
    ...initLayoutState,
    headerTransparent: !getStorage(StorageKey.SkipIntroduction),
  }));

  const updateState: LayoutActions['updateState'] = useCallback(
    (newState) =>
      setState((prevState) => ({
        ...prevState,
        ...newState,
      })),
    []
  );

  return (
    <LayoutContext.Provider
      value={{
        headerFloating: isHeaderFloating,
        isNavbarProfile: isActiveProfile,
        setState,
        updateState,
        ...state,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const LayoutConsumer = LayoutContext.Consumer;

export const useLayout = () => useContext(LayoutContext);
