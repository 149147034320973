import React from 'react';

const IcNavHide = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.800781"
        y="0.570312"
        width="23.5195"
        height="2"
        rx="1"
        fill="#C9C8CC"
      />
      <rect
        x="11.3516"
        y="7.57031"
        width="12.9688"
        height="2"
        rx="1"
        fill="#C9C8CC"
      />
      <rect
        x="11.3516"
        y="14.5703"
        width="12.9688"
        height="2"
        rx="1"
        fill="#C9C8CC"
      />
      <rect
        x="0.800781"
        y="21.5703"
        width="23.5195"
        height="2"
        rx="1"
        fill="#C9C8CC"
      />
      <path
        d="M1.696 12.7657C1.22666 12.3663 1.22666 11.6417 1.696 11.2424L5.54243 7.96986C6.19183 7.41736 7.19043 7.87888 7.19043 8.7315L7.19043 15.2765C7.19043 16.1291 6.19183 16.5907 5.54243 16.0382L1.696 12.7657Z"
        fill="#C9C8CC"
      />
    </svg>
  );
};

export default IcNavHide;
