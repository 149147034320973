import { ECurrency } from 'constants/currency';
import { OrderType } from 'constants/orderConstants';
import { pmsCustomerRequest, pmsPublicRequest } from './requests';

export type OrderItems = {
  placeHolderId?: number;
  productChooseId?: number;
  quantity?: number;
  layoutId?: number;
}

export type FetchOrderParams = {
  orderId?: number;
  currencyCode?: ECurrency;
  projectId?: number;
  floorId?: number;
  layoutId?: number;
  segmentId?: number;
  financialPackageId?: number;
  personalPropertiesId?: number;
  type?: OrderType;
  orderItems?: OrderItems[];
  documentId?: string;
};

export const fetchUpdateTempOrderApi = async ({ orderId, ...params }: FetchOrderParams) =>
  pmsCustomerRequest.put(`/orders/update-temp/${orderId}`, { ...params });

export const fetchCreateOrderApi = async ({ ...params }: FetchOrderParams) =>
  pmsCustomerRequest.post(`/orders`, { ...params });

export const fetchUpdateOrderApi = async ({ orderId, ...params }: FetchOrderParams) =>
  pmsCustomerRequest.put(`/orders/${orderId}`, { ...params });

export const fetchDetailOrderApi = async ({ orderId, ...params }: FetchOrderParams) =>
  pmsCustomerRequest.get(`/orders/${orderId}`, { params });

export const fetchConfirmOrderApi = async ({ orderId, currencyCode }: FetchOrderParams) =>
  pmsCustomerRequest.put(`/orders/confirm/${orderId}`, { currencyCode });

export const fetchMyOrderApi = async ({ ...params }: FetchOrderParams) =>
  pmsCustomerRequest.get(`/orders/my`, { params });

export const fetchSendDocumentPandadoc = async ({ documentId }: FetchOrderParams) =>
  pmsCustomerRequest.get(`/orders/send-document/${documentId}`);

export const fetchStatusDocumentPandadoc = async ({ documentId }: FetchOrderParams) =>
  pmsPublicRequest.get(`/panda-doc/document/${documentId}`);

export const fetchViewDocumentPandadoc = async ({ documentId }: FetchOrderParams) =>
  pmsPublicRequest.get(`/panda-doc/document/download/${documentId}`, {
    responseType: 'blob'
  });