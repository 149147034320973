import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

// ICONS
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandLess from '@mui/icons-material/ExpandLess';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import IcNavShow from 'components/icons/IcNavShow';
import BuildOutlinedIcon from 'components/sgvIcons/BuildOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Logo from '../logos/Logo';

// COMPONENTS
import { Box, Collapse, Divider, Stack, useTheme } from '@mui/material';
import { FetchProjectsParams, fetchProjectPriceByDesignHomeApi, fetchProjectPriceByIdApi } from 'apis/projectApis';
import IcCalculator from 'components/icons/IcCalculator';
import IcNavHide from 'components/icons/IcNavHide';
import CustomDrawer from 'components/modals/CustomDrawer';
import { NavbarPrice } from 'constants/homeConstants';
import { PageRoute, PageRouteName, PageRouteNameOrderedValidation, PageRouteParent } from 'constants/route';
import { StorageKey } from 'constants/storage';
import { useLayout } from 'contexts/layoutContext';
import { usePriceContext } from 'contexts/priceContext';
import { usePrice, useRoute } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getStorage, hasStorage, setStorage } from 'services/storageService';
import { setDesignHome, setFinancialPlan } from 'store/designHome/designHomeActions';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';
import { ECurrency } from 'constants/currency';
import IcFAQHead from 'components/icons/IcFAQHead';
import { checkFlowBareShell } from 'utils/dataHelpers';
import { toast } from 'react-toastify';
import { useOrderContext } from 'contexts/ordersContext';
import { OrderStatus, OrderStep } from 'model/order';
import { isEmpty } from 'lodash';

type Props = {
  children: ReactNode;
}

const NEW_NAME_PATHS = Object.values(PageRouteName);

const MainSideBar = ({ children }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { location: { pathname } } = history;
  const {
    designHome: {
      blockId,
      segmentId,
      layoutId,
      floorId,
      planId,
      productItems,
      projectId: getProjectId,
      locationId: getLocationId,
      totalBlocks,
      orderStatus,
      collectionId,
      personalityId,
      activeSellBareshell,
      orderType,
    },
    auth: { tokenUMS }
  } = useSelector(selectDesignHome);
  const dispatch = useDispatch();
  const { params, redirect } = useRoute();
  const { locationId, projectId } = params as {
    locationId: number;
    projectId: number;
  };

  const { preferCurrency } = usePriceContext();
  const { exchangePriceWithCurrency } = usePrice();
  const { orders } = useOrderContext();
  const { isNavbarHide, updateState, isNavbarProfile } = useLayout();
  const [priceMenu, setPriceMenu] = useState<NavbarPrice>();

  const [openMenuMyHome, setOpenMenuMyHome] = useState(false);
  const [openMenuMyDesign, setOpenMenuMyDesign] = useState(false);
  const [openMenuMyFinancial, setOpenMenuMyFinancial] = useState(false);
  const [activeNavbar, setActiveNavbar] = useState<string>('');
  const [showDrawer, setShowDrawer] = useState(false);

  const ItemListMenu = useMemo(() => {
    return [
      {
        menuTitle: t('My Home'),
        menuIcon: <HomeOutlinedIcon />,
        menuCategory: [...PageRouteParent.MyHome],
        openMenu: openMenuMyHome,
        onClick: () => setOpenMenuMyHome(!openMenuMyHome),
        subMenu: [
          {
            menuSubTitle: t('Choose Block'),
            menuSubCategory: PageRouteName.ChooseBlock,
            onClick: () => {
              // Validate cannot click choose block if you ordered
              if (PageRouteNameOrderedValidation.some(item => pathname.includes(item)) &&
                (getStorage(StorageKey.StepOrder) === OrderStep.Ordered ||
                  orderStatus === OrderStatus.Booked ||
                  orderStatus === OrderStatus.Bought ||
                  orderStatus === OrderStatus.Cancelled ||
                  orderStatus === OrderStatus.Pending ||
                  orderStatus === OrderStatus.PreOrdered)) {
                return;
              }
              if (!Number(locationId) && !Number(projectId)) {
                redirect({ path: PageRoute.HomePage });
                setActiveNavbar(PageRouteName.ChooseBlock);
              } else {
                if (totalBlocks && totalBlocks > 1) {
                  redirect({
                    path: PageRoute.ChooseBlock,
                    params: {
                      locationId,
                      projectId,
                    }
                  });
                  setActiveNavbar(PageRouteName.ChooseBlock);

                  // reset floor
                  setStorage(
                    StorageKey.StepYourDesign,
                    { ...getStorage(StorageKey.StepYourDesign), floorId: undefined, segmentId: undefined }
                  );
                  dispatch(setDesignHome({
                    floorId: undefined,
                    segmentId: undefined,
                  }));
                }
              };
            }
          },
          {
            menuSubTitle: t('Choose Segment'),
            menuSubCategory: PageRouteName.ChooseSegment,
            onClick: () => {
              if (Number(locationId) === 114 && Number(projectId) === 5 && segmentId === 3634) return;
              if (PageRouteNameOrderedValidation.some(item => pathname.includes(item)) &&
                (getStorage(StorageKey.StepOrder) === OrderStep.Ordered ||
                  orderStatus === OrderStatus.Booked ||
                  orderStatus === OrderStatus.Bought ||
                  orderStatus === OrderStatus.Cancelled ||
                  orderStatus === OrderStatus.Pending ||
                  orderStatus === OrderStatus.PreOrdered)) {
                return;
              }
              if (!Number(locationId) && !Number(projectId)) {
                redirect({ path: PageRoute.HomePage });
              } else {
                redirect({
                  path: PageRoute.ChooseSegment,
                  params: {
                    locationId,
                    projectId,
                  }
                });
                setActiveNavbar(PageRouteName.ChooseSegment);
              }
            }
          }
        ],
        isHidden: true,
        isHideConfirm: true,
      },
      {
        menuTitle: t('My Design'),
        menuIcon: <BuildOutlinedIcon />,
        menuCategory: [...PageRouteParent.MyDesign],
        openMenu: openMenuMyDesign,
        onClick: () => setOpenMenuMyDesign(!openMenuMyDesign),
        isHidden: true,
        isHideConfirm: true,
      },
      {
        menuTitle: t('My Financial Package'),
        menuIcon: <AccountBalanceOutlinedIcon />,
        menuCategory: [...PageRouteParent.MyFinancialPackage],
        openMenu: openMenuMyFinancial,
        onClick: () => setOpenMenuMyFinancial(!openMenuMyFinancial),
        isHidden: true,
        isHideConfirm: true,
      },
      {
        menuTitle: t('My Order'),
        menuIcon: <EventNoteIcon />,
        menuCategory: [...PageRouteParent.MyOrderConfirm],
        openMenu: false,
        onClick: () => {
          handleRedirectOrderPage();
          setActiveNavbar(PageRouteName.MyOrderConfirm);
        },
        isHidden: tokenUMS ? true : false,
      },
      {
        menuTitle: t('FAQs'),
        menuIcon: <IcFAQHead width="27px" height="27px" />,
        menuCategory: [],
        openMenu: false,
        onClick: () => {
          redirect({
            path: PageRoute.FAQ
          });
        },
        isHidden: tokenUMS ? true : false,
      },
      {
        menuTitle: t('My Profile'),
        menuIcon: <PersonOutlineOutlinedIcon />,
        menuCategory: [...PageRouteParent.MyProfile],
        openMenu: false,
        onClick: () => {
          redirect({
            path: PageRoute.PersonalProfile,
            params: {
              locationId: locationId || getLocationId || '',
              projectId: projectId || getProjectId || '',
            }
          });
          setActiveNavbar(PageRouteName.PersonalProfile);
        },
        isHidden: tokenUMS ? true : false,
      },
    ]
  }, [openMenuMyHome, openMenuMyDesign, openMenuMyFinancial, activeNavbar, orderStatus, orders]);

  const fetchPriceMenuApi = async () => {
    if (pathname.includes(PageRouteName.PersonalProfile)) return;
    if (blockId && segmentId && floorId && planId) {
      let params: FetchProjectsParams = {
        projectId,
        blockId,
        planId,
        floorId,
        segmentId,
        layoutId: layoutId || undefined,
        productItems: productItems || undefined,
        personalityProperties: collectionId || personalityId || undefined,
      };
      if (pathname.includes(PageRouteName.ChooseSegment) || pathname.includes(PageRouteName.ChooseBlock)) {
        params = {
          ...params,
          layoutId: undefined,
          productItems: undefined,
          personalityProperties: undefined,
        }
      }
      const { data } = await fetchProjectPriceByDesignHomeApi({ ...params, currencyCode: preferCurrency, projectId: Number(projectId) });
      if (preferCurrency === String(getStorage(StorageKey.Currency))) {
        setPriceMenu(data);

        if (!layoutId && !(productItems || []).length) {
          dispatch(setDesignHome({
            floorId: getStorage(StorageKey.StepYourDesign)?.floorId,
            blockId: data?.blockId,
            segmentId: getStorage(StorageKey.StepYourDesign)?.segmentId,
            extraPrice: data?.extraPrice || 0,
          }));
          setStorage(
            StorageKey.StepYourDesign,
            {
              ...getStorage(StorageKey.StepYourDesign),
              blockId: data?.blockId,
              floorId: getStorage(StorageKey.StepYourDesign)?.floorId || data?.floorId || '',
              extraPrice: data?.extraPrice || 0,
            }
          );
        }

        setStorage(
          StorageKey.FinancialPackage,
          {
            ...getStorage(StorageKey.FinancialPackage),
            totalPrice: data?.totalVatPrice || 0,
          }
        );
        dispatch(setFinancialPlan({
          totalPrice: data?.totalVatPrice || 0,
        }));
      }
    } else {
      const params: FetchProjectsParams = hasStorage(StorageKey.StepYourDesign)
        ? { projectId, blockId: getStorage(StorageKey.StepYourDesign)?.blockId }
        : { projectId }
      const { data } = await fetchProjectPriceByIdApi(
        {
          ...params,
          currencyCode: preferCurrency,
          defaultType: pathname.includes(PageRouteName.ChooseBlock) ? 'block' : 'floor',
          floorId: pathname.includes(PageRouteName.ChooseBlock) ? undefined : floorId || undefined,
        });
      if (preferCurrency === String(getStorage(StorageKey.Currency))) {
        if (!isEmpty(data)) {
          setPriceMenu(data);
          setStorage(
            StorageKey.StepYourDesign,
            {
              ...getStorage(StorageKey.StepYourDesign),
              blockId: data?.blockId,
              extraPrice: data?.extraPrice || 0,
            }
          );
          dispatch(setDesignHome({
            blockId: data?.blockId,
            extraPrice: data?.extraPrice || 0,
          }));

          setStorage(
            StorageKey.FinancialPackage,
            {
              ...getStorage(StorageKey.FinancialPackage),
              totalPrice: data?.totalVatPrice,
            }
          );
          dispatch(setFinancialPlan({
            totalPrice: data?.totalVatPrice,
          }));
        }
      }
    }
  }

  const handleActiveNavbar = () => {
    const routeActive = NEW_NAME_PATHS.find(item => pathname.includes(item));
    setActiveNavbar(routeActive!);
  }

  const handleRedirectOrderPage = useCallback(() => {
    if (!isEmpty(orders)) {
      redirect({
        path: PageRoute.MyOrderConfirm,
        params: {
          orderId: Number(orders[orders?.length - 1]?.id),
          locationId: locationId || 3,
          projectId: Number(orders[orders?.length - 1]?.projectId),
        }
      });
    } else {
      toast.error(t('You have not ordered yet!'));
    }
  }, [orders])

  useEffect(() => {
    handleActiveNavbar();
  }, [pathname])

  useEffect(() => {
    if (!pathname.includes(PageRouteName.MyOrderConfirm)) {
      fetchPriceMenuApi();
    }
  }, [blockId, segmentId, layoutId, productItems, floorId])

  const renderMenuFitOut = () => {
    return <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      columnGap={1}
      style={{ padding: '.75rem' }}>
      <div className='text-sub-name'>{t('Fit Out')}</div>
      <div className='text-sub-name white'>
        {exchangePriceWithCurrency(
          priceMenu?.fitoutPrice || 0,
          preferCurrency === ECurrency.Usd ? 'prefix' : 'suffix'
        )}
      </div>
    </Stack>
  };

  const renderMenuFurniture = () => {
    return <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      columnGap={1}
      style={{ padding: '.75rem', background: theme.palette.default.contrastText }}>
      <div className='text-sub-name'>{t('Furniture')}</div>
      <div className='text-sub-name white'>
        {exchangePriceWithCurrency(
          priceMenu?.furniturePrice || 0,
          preferCurrency === ECurrency.Usd ? 'prefix' : 'suffix'
        )}
      </div>
    </Stack>
  };

  const renderMenuTotalVat = () => {
    return <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      columnGap={1}
      style={{ padding: '.75rem', background: theme.palette.primary.main }}>
      <Box style={{ width: '105px', wordBreak: 'break-word' }}>
        <Box className='text-sub-name white'>{t('Total')} ({t('excl. VAT')})</Box>
      </Box>
      <div className='text-sub-name white italic'>
        {exchangePriceWithCurrency(
          priceMenu?.totalPrice || 0,
          preferCurrency === ECurrency.Usd ? 'prefix' : 'suffix'
        )}
      </div>
    </Stack>
  }

  const renderMenuPrice = () => {
    return (
      <Box>
        {!isNavbarProfile &&
          <Box className='group-menu-price'>
            <Stack padding=".75rem 1rem" flexDirection="row" columnGap={1.5} alignItems="center">
              <IcCalculator width="20px" height="20px" />
              <div className='text-price'>{t('Price')}</div>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              columnGap={1}
              style={{ padding: '.75rem', background: theme.palette.default.contrastText }}>
              <div className='text-sub-name'>{t('Bareshell')}</div>
              <div className='text-sub-name white'>
                {exchangePriceWithCurrency(
                  priceMenu?.bareshellPrice || 0,
                  preferCurrency === ECurrency.Usd ? 'prefix' : 'suffix'
                )}
              </div>
            </Stack>
            {!activeSellBareshell
              ? renderMenuFitOut()
              : pathname.includes(PageRouteName.ChooseSegment)
                ? renderMenuFitOut()
                : !checkFlowBareShell(orderType!) && renderMenuFitOut()
            }
            {!activeSellBareshell
              ? renderMenuFurniture()
              : pathname.includes(PageRouteName.ChooseSegment)
                ? renderMenuFurniture()
                : !checkFlowBareShell(orderType!) && renderMenuFurniture()
            }
            {!activeSellBareshell
              ? renderMenuTotalVat()
              : pathname.includes(PageRouteName.ChooseSegment)
                ? renderMenuTotalVat()
                : !checkFlowBareShell(orderType!) && renderMenuTotalVat()
            }
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              columnGap={1}
              style={{ padding: '.75rem', background: theme.palette.default.contrastText }}>
              <Box style={{ width: '105px', wordBreak: 'break-word' }}>
                <Box className='text-sub-name italic'>{t('VAT')} ({priceMenu?.vat}%)</Box>
              </Box>
              <div className='text-sub-name white italic'>
                {exchangePriceWithCurrency(
                  priceMenu?.vatPrice || 0,
                  preferCurrency === ECurrency.Usd ? 'prefix' : 'suffix'
                )}
              </div>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              columnGap={1}
              style={{ padding: '1.5rem .75rem', background: theme.palette.accent.main }}>
              <Box style={{ width: '105px', wordBreak: 'break-word' }}>
                <Box className='text-sub-name white'>{t('Total')} ({t('incl. VAT')})</Box>
              </Box>
              <div className='text-sub-name white large'>
                {exchangePriceWithCurrency(
                  priceMenu?.totalVatPrice || 0,
                  preferCurrency === ECurrency.Usd ? 'prefix' : 'suffix'
                )}
              </div>
            </Stack>
          </Box>
        }
      </Box>
    );
  }

  const renderMenuLink = () => {
    return (ItemListMenu || []).map((item, index) => (
      <Box key={index}>
        <Box className='group-menu-header'>
          {(item.isHidden) &&
            <Box>
              {(
                (pathname.includes(PageRouteName.MyOrderConfirm) ||
                  pathname.includes(PageRouteName.PersonalProfile))
                // && orderStatus !== OrderStatus.New
              )
                ? !item.isHideConfirm
                  ? MenuListItem(item) : null
                : MenuListItem(item)
              }
            </Box>
          }
        </Box>
        {(index === 2 && !isNavbarHide) && renderMenuPrice()}
      </Box>
    ))
  }

  const MenuListItem = (item: any) => {
    return (
      <Box>
        <Stack
          className={`box-menu-header ${(item.menuCategory || []).some((menu: any) => menu.includes(activeNavbar!)) && 'active'}`}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={item.onClick}
        >
          <Stack flexDirection="row" columnGap={1} alignItems="center">
            {item.menuIcon}
            <div className='text-menu'>{item.menuTitle}</div>
          </Stack>
          {item.subMenu?.length && <Box>
            {item.openMenu
              ? <ExpandLess color='disabled' fontSize="medium" />
              : <KeyboardArrowRightIcon color='disabled' fontSize="medium" />}
          </Box>}
        </Stack>
        <Collapse in={item.openMenu}>
          {(item.subMenu || [])?.map((menu: any, index: number) => (
            <Box key={index} onClick={menu.onClick} className={`text-sub-menu ${menu.menuSubCategory.includes(activeNavbar!) && 'active'}`}>
              {menu.menuSubTitle}
            </Box>
          ))}
        </Collapse>
      </Box>
    )
  }

  return (
    <WrapperStyled>
      {!pathname.includes(PageRouteName.ShareSocial) &&
        <Box sx={{ display: { xs: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
          <Nav
            isNavbarHide={isNavbarHide}
            className="left-bar area-sidebar-main"
            aria-label="left-menu"
          >
            <Header>
              <Box onClick={() => redirect('/')} style={{ cursor: 'pointer' }}>
                {!isNavbarHide &&
                  <Logo.LevitateLogo width="120px" />
                }
              </Box>
              <Box
                onClick={() => updateState({ isNavbarHide: !isNavbarHide })}
                style={{ cursor: 'pointer' }}
              >
                {!isNavbarHide
                  ? <IcNavHide width="25px" height="26px" />
                  : <IcNavShow width="25px" height="26px" />}
              </Box>
            </Header>
            {!isNavbarHide &&
              <Box>
                <Divider />
                {renderMenuLink()}
              </Box>
            }
          </Nav>
        </Box>}
      {!pathname.includes(PageRouteName.ShareSocial) && <Box
        onClick={() => setShowDrawer(true)}
        className='dashboardSideBar'
        sx={{ display: { xs: 'block', md: 'block', lg: 'none', xl: 'none' } }}
      >
        <DashboardIcon style={{ width: '40px', height: '40px' }} color='accent' />
      </Box>}

      <CustomDrawer
        open={showDrawer} onClose={(open: boolean) => setShowDrawer(open)}
      >
        <Box>
          <Divider />
          {renderMenuLink()}
        </Box>
      </CustomDrawer>

      <Wrapper
        isNavbarHide={isNavbarHide}
      >
        {children}
      </Wrapper>
    </WrapperStyled>
  )
};

type StyledProps = {
  isNavbarHide?: boolean;
};

const WrapperStyled = styled.section`
  .dashboardSideBar {
    position: fixed;
    top: 45%;
    right: 30px;
    z-index: 1000;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    padding: 1rem;
    box-shadow: 0px 4px 40px rgba(88, 149, 202, 0.16);
  }
`;

const Header = styled.header<StyledProps>`
  height: 72px;
  background-color: ${(p) => p.theme.palette.default.light};

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
`;

const Nav = styled.nav<StyledProps>`
  width: ${(p) => (!p.isNavbarHide ? '18rem' : '4rem')};
  background-color: ${(p) => p.theme.palette.default.light};
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1299;
  height: 100%;
  color: #C9C8CC;
  transition: all .2s ease-in-out;

  @media only screen and (min-width: 1200px) { 
    width: ${(p) => (!p.isNavbarHide ? '15rem' : '4rem')};
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .MuiTypography-root {
    font-size: 0.875rem; // 14px
  }

  .MuiList-root .Mui-selected {
    background-color: ${(p) => p.theme.palette.accent.main};

    .MuiTypography-root {
      font-weight: 500;
    }

    &:hover {
      background-color: ${(p) => p.theme.palette.accent.mainHover};
    }
  }

  .group-menu-header {
    margin: 1.5rem 0 0 1.25rem;
    
    .box-menu-header {
      padding: .7rem 0 .7rem .7rem;
      cursor: pointer;

      &.active {
        background: linear-gradient(90deg, ${p => p.theme.palette.secondary.main} 18.6%, ${p => p.theme.palette.default.contrastText} 100%);
        color: ${(p) => p.theme.palette.accent.main};
        
        .text-menu {
          font-weight: 500;
        }
      }

      .text-menu {
        font-weight: 300;
        font-size: 14px;
      }
    }

    .text-sub-menu {
      margin: 1rem 1.5rem 0;
      font-weight: 300;
      font-size: 14px;
      padding-left: .5rem;
      cursor: pointer;
      
      &.active {
        border-left: 2px solid ${(p) => p.theme.palette.accent.main};
        font-weight: 500;
        color: ${(p) => p.theme.palette.accent.main};
      }
    }
  }

  .group-menu-price {
    border: 1px solid ${(p) => p.theme.palette.primary.main};
    margin: 1rem .7rem 0;

    .text-price {
      font-weight: 500;
      font-size: 16px;
      color: ${(p) => p.theme.palette.accent.main};
    }

    .text-sub-name {
      font-size: 13px;
      width: inherit;
      word-break: break-word;

      &.large {
        font-size: 15px
      }

      &.white {
        color: #fff;
      }

      &.italic {
        font-style: italic;
      }
    }
  }
`;

const Wrapper = styled.div<StyledProps>`
  width: 100%;
  margin-left: auto;
  transition: all .2s ease-in-out;

  @media only screen and (min-width: 1200px) { 
    width: ${(p) => (!p.isNavbarHide ? 'calc(100% - 15rem)' : 'calc(100% - 4rem)')};
  }
`

export default MainSideBar;

// color={[PageRoute.ChooseBlock, PageRoute.ChooseSegment].includes(pathname as PageRoute)
//   ? 'accent' : 'disabled'} 