import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Select from 'components/inputs/Select';

// SERVICES
import { changeLanguage } from 'services/localeService';

// CONSTANTS
import { Obj, SelectOption } from 'constants/types';
import { Lang, formatFlagUrl, formatLanguageCode, genFlagUrl } from 'constants/i18n';
import { fetchLanguageApi } from 'apis/projectApis';
import { isEmpty } from 'lodash';
import { ELanguageLocales } from 'constants/currency';

type LanguageOption = {
  active: boolean;
  code: string;
  enable: boolean;
  id: number;
  name: string;
}

const LanguageDropdown = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const [languageOptions, setLanguageOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchLanguageApi();
      if (!isEmpty(data)) {
        const LANGUAGE_OPTIONS: SelectOption[] = data.map((language: LanguageOption) => ({
            label: (
              <Stack direction="row" gap={1.5} alignItems="center">
                <Avatar
                  src={genFlagUrl(formatFlagUrl(language?.code! as ELanguageLocales))}
                  variant="square"
                  sx={{
                    width: '1.2rem !important',
                    height: '1rem !important',
                  }}
                  alt='flag-language'
                />
                <span>{formatLanguageCode(language?.code as ELanguageLocales).toUpperCase()}</span>
              </Stack>
            ),
            value: language?.code,
            data: formatLanguageCode(language?.code as ELanguageLocales)
          })).sort((a: Obj, b: Obj) =>
          a.data.localeCompare(b.data));
        setLanguageOptions(LANGUAGE_OPTIONS);
      }
    })()
  }, [])

  return (
    <Select
      options={languageOptions}
      value={language || languageOptions![0].value || ''}
      hideSelected
      fullWidth
      required
      onChange={(event) => changeLanguage(event.target.value as Lang)}
      height='300px'
    />
  );
};

export default LanguageDropdown;
