export { default as IcArrowRight } from './IcArrowRight';
export { default as IcLineArrowDown } from './IcLineArrowDown';
export {default as IcChevronRight} from './IcChevronRight';
export {default as IcChevronLeft} from './IcChevronLeft';
export {default as IcPen} from './IcPen';
export {default as IcTag} from './IcTag';
export {default as IcPlay} from './IcPlay';
export {default as IcCaretDown} from './IcCaretDown';
export {default as IcCheckbox} from './IcCheckbox';
export {default as IcThinLeft} from './IcThinLeft';
export {default as IcThinRight} from './IcThinRight';
export {default as IcLightArrowDown} from './IcLightArrowDown';
export {default as IcLightArrowUp} from './IcLightArrowUp';
export {default as IcVolumeUp} from './IcVolumeUp';
export {default as IcVolumeMute} from './IcVolumeMute';
export {default as IcSubTittle} from './IcSubTittle';
export {default as IcHeartBold} from './IcHeartBold';
export {default as IcHeartSolid} from './IcHeartSolid';
export {default as IcSearch} from './IcSearch';
export {default as IcOutlineBank} from './IcOutlineBank';
export {default as IcOutlineHome} from './IcOutlineHome';
export {default as IcOutlineBuild} from './IcOutlineBuild';
export {default as IcOutlinePlay} from './IcOutlinePlay';
export {default as IcOutlinePlayV2} from './IcOutlinePlayV2';
export {default as IcOutlinePause} from './IcOutlinePause';
export {default as IcOutlinePreference} from './IcOutlinePreference';
export {default as IcOutlineSoundOff} from './IcOutlineSoundOff';
export {default as IcOutlineSoundOn} from './IcOutlineSoundOn';
export {default as IcOutlineArrowDown} from './IcOutlineArrowDown';
export {default as IcOutlineArrowLeft} from './IcOutlineArrowLeft';
export {default as IcOutlineMenu} from './IcOutlineMenu';
export {default as IcOutlineClose} from './IcOutlineClose';
export {default as IcOutlineBed} from './IcOutlineBed';
export {default as IcOutlineChevronDown} from './IcOutlineChevronDown';
export {default as IcOutlineChevronLeft} from './IcOutlineChevronLeft';
export {default as IcOutlineEnvironment} from './IcOutlineEnvironment';
export {default as IcFilledEnvironment} from './IcFilledEnvironment';
export {default as IcOutlineProfile} from './IcOutlineProfile';
export {default as IcOutlineSave} from './IcOutlineSave';
export {default as IcOutlineSmile} from './IcOutlineSmile';
export {default as IcOutlineTag} from './IcOutlineTag';
export {default as IcOutlineContactBook} from './IcOutlineContactBook';
export {default as IcOutlineCalculator} from './IcOutlineCalculator';
export {default as IcOutlineNoProfile} from './IcOutlineNoProfile';
export {default as IcOutlineNoWaiting} from './IcOutlineNoWaiting';
export {default as IcCircleError} from './IcCircleError';
export {default as IcCirclePlus} from './IcCirclePlus';
export {default as IcOutlineChevronRight} from './IcOutlineChevronRight';
export {default as IcOutlineEarth} from './IcOutlineEarth';
export {default as IcOutlineCameraAngle} from './IcOutlineCameraAngle';
export {default as IcOutlineCheckNone} from './IcOutlineCheckNone';
export {default as IcCircleCheckNone} from './IcCircleCheckNone';
export {default as IcOutlineSubtitle} from './IcOutlineSubtitle';
export {default as MyOrderIcon} from './MyOrderIcon';
export {default as SignoutIcon} from './SignoutIcon';
export {default as UserIcon} from './UserIcon';
export {default as FAQIcon} from './FAQIcon';
export {default as IcInfo} from './IcInfo';
export {default as CompleteIcon} from './CompleteIcon';
export {default as IcFacebookLight} from './IcFacebookLight';
export {default as IcInstagramLight} from './IcInstagramLight';
export {default as IcTwitterLight} from './IcTwitterLight';
