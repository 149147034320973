import { Drawer, DrawerProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

type CustomDrawer = DrawerProps & {
  open: boolean;
  onClose: (open: boolean) => void;
  width?: string;
};

const CustomDrawer = ({
  open,
  onClose,
  children,
  width
}: CustomDrawer) => {
  return (
    <CustomDrawerStyled
      PaperProps={{
        sx: { width: {xs: '80%', sm: '45%', md: '45%'}, backgroundColor: '#3E3B45' },
      }}
      open={open} onClose={() => onClose(!open)}
    >
      { children }
    </CustomDrawerStyled>
  )
};

const CustomDrawerStyled = styled(Drawer)`
  .header-filter{
    padding: 2rem;
    padding-top: 0rem;
    margin-top: 2rem;

    .MuiSelect-select{
      font-size: 13px;
    }

    &-slider{
      .MuiFormControl-root{
        width: 250px !important;
      }
    }

    &-rating{
      .MuiRating-root{
        .MuiRating-icon{
          padding-right: 15px;
        }
        svg{
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .drawer-filter-head{
    background-color: ${p => p.theme.palette.accent.main};
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #605E64;

    &-close{
      cursor: pointer;
      padding: 5px 0px 5px 0;

      svg{
        width: 25px;
        height: 25px;
      }
    }
  }

  .header-filter-bedroom{
    .ButtonSelect-root{
      flex-wrap: wrap;
    }
    button{
      min-width: 10px !important;
      flex: 0 0 calc(50% - 8px) !important;
      padding: 6px 10px !important;
      font-size: 12px !important;
    }
  }

  label{
    font-size: 13px;
  }

  .group-menu-header {
    margin: 1.5rem 0 0 1.25rem;
    
    .box-menu-header {
      padding: .7rem 0 .7rem .7rem;
      cursor: pointer;

      &.active {
        background: linear-gradient(90deg, #46434E 18.6%, #4E4B55 100%);
        color: ${(p) => p.theme.palette.accent.main};
        
        .text-menu {
          font-weight: 500;
        }
      }

      .text-menu {
        font-weight: 300;
        font-size: 14px;
      }
    }

    .text-sub-menu {
      margin: 1rem 1.5rem 0;
      font-weight: 300;
      font-size: 14px;
      padding-left: .5rem;
      cursor: pointer;
      
      &.active {
        border-left: 2px solid ${(p) => p.theme.palette.accent.main};
        font-weight: 500;
        color: ${(p) => p.theme.palette.accent.main};
      }
    }
  }

  .group-menu-price {
    border: 1px solid #5F5C64;
    margin: 1rem .7rem 0;

    .text-price {
      font-weight: 500;
      font-size: 16px;
      color: ${(p) => p.theme.palette.accent.main};
    }

    .text-sub-name {
      font-size: 13px;

      &.large {
        font-size: 15px
      }

      &.white {
        color: #fff;
      }

      &.italic {
        font-style: italic;
      }
    }
  }
`;

export default CustomDrawer;