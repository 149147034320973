import i18n from 'i18next';
import loadable from '@loadable/component';
import React, { Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// COMPONENTS
import IconProgress from 'components/progress/IconProgress';

// LAYOUTS
import MainLayout from 'components/layouts/MainLayout';

// CONTEXTS
import { LayoutProvider } from 'contexts/layoutContext';
import { PriceProvider } from 'contexts/priceContext';

// THEME and CSS
import './App.scss';

// SERVICES
import { changeLanguage } from 'services/localeService';

// OLD PAGES
import { Lang } from 'constants/i18n';

import MainSideBar from 'components/layouts/navigation/MainSideBar';
import { PageRoute } from 'constants/route';
import { OrderProvider } from 'contexts/ordersContext';
import { isEmpty } from 'lodash';
import { TextContentBrand } from 'model/textContent';
import { useDispatch } from 'react-redux';
import { setTextContentBrand } from 'store/designHome/designHomeActions';

// NEW PAGES
const HomePage = loadable(() => import('pages/HomePage/HomePage'));
const ProjectListing = loadable(
  () => import('pages/ProjectListing/ProjectListing')
);
const ProjectViewDetail = loadable(
  () => import('pages/ProjectListing/ProjectViewDetail')
);

// INFORMATION
const PrivacyPolicy = loadable(
  () => import('pages/PrivacyPolicy/PrivacyPolicy')
);
const FAQ = loadable(
  () => import('pages/FAQ/FAQ')
);
const CookiePolicy = loadable(() => import('pages/CookiePolicy/CookiePolicy'));
const TermsOfUse = loadable(() => import('pages/TermsOfUse/TermsOfUser'));
const YourDesign = loadable(() => import('pages/YourDesign/YourDesign'));

const BlockChoosingPage = loadable(() => import('pages/YourHome/BlockChoosing'));
const SegmentFloorChoosing = loadable(() => import('pages/YourHome/SegmentFloorChoosing'));
const CollectionChoosing = loadable(() => import('pages/YourHome/CollectionChoosing'));
const Interact2D = loadable(() => import('pages/YourHome/Interact2D'));

// Financial Package
const MyFinancialPackage = loadable(() => import('pages/MyFinancialPackage/FinancialQuestion'));
const MyFinancialCombine = loadable(() => import('pages/MyFinancialPackage/FinancialCombine'));
const MyFinancialBank = loadable(() => import('pages/MyFinancialPackage/FinancialBank'));
const MortagePlan = loadable(() => import('pages/MortgagePlan/MortagePlan'));
const Share = loadable(() => import('pages/Social/Share'));

// Profile
const PersonalProfile = loadable(() => import('pages/MyProfile/PersonalProfile'));

// My Order Confirm
const MyOrderConfirm = loadable(() => import('pages/MyOrder/OrderConfirm'));

// Contact Us
const ContactUs = loadable(() => import('pages/ContactUs/ContactUs'));

// Partner Levitate
const PartnerLevitate = loadable(() => import('pages/PartnerLevitate/PartnerLevitate'));

const NEW_PAGE_PATHS = Object.values(PageRoute);

type Props = {
  textContentBrand?: TextContentBrand;
}

const App = ({ textContentBrand }: Props) => {

  const dispatch = useDispatch();

  // useDocumentTitle({
  //   siteTitle: 'Levitate - For You By You',
  // });

  useEffect(() => {
    changeLanguage(i18n.language as Lang);
  }, []);

  useEffect(() => {
    if (!isEmpty(textContentBrand)) {
      dispatch(setTextContentBrand({ textBrand: textContentBrand?.textBrand || 'Levitate' }));
    }
  }, [textContentBrand])

  return (
    <Router>
      <Suspense fallback={<IconProgress fullScreen noRender />}>
        <Switch>
          <Route exact path={NEW_PAGE_PATHS}>
            <PriceProvider>
              <LayoutProvider>
                <OrderProvider>
                  <MainLayout>
                    <Suspense fallback={<IconProgress fullScreen noRender />}>
                      <Switch>
                        <Route
                          exact
                          path={PageRoute.HomePage}
                          component={HomePage}
                        />
                        <Route
                          exact
                          path={PageRoute.PartnerLevitate}
                          component={PartnerLevitate}
                        />
                        <Route
                          exact
                          path={PageRoute.ProjectListing}
                          component={ProjectListing}
                        />
                        <Route
                          exact
                          path={PageRoute.ProjectDetail}
                          component={ProjectViewDetail}
                        />
                        <Route
                          path={PageRoute.PrivacyPolicy}
                          component={PrivacyPolicy}
                        />
                        <Route
                          path={PageRoute.FAQ}
                          component={FAQ}
                        />
                        <Route
                          path={PageRoute.TermsOfUse}
                          component={TermsOfUse}
                        />
                        <Route
                          path={PageRoute.CookiePolicy}
                          component={CookiePolicy}
                        />
                        <Route
                          path={PageRoute.ContactUs}
                          component={ContactUs}
                        />
                        <Route exact path={PageRoute.ChooseCollection} component={CollectionChoosing} />
                        <Route exact path={NEW_PAGE_PATHS}>
                          <MainSideBar>
                            <Route exact path={PageRoute.ChooseBlock} component={BlockChoosingPage} />
                            <Route exact path={PageRoute.ChooseSegment} component={SegmentFloorChoosing} />
                            <Route exact path={PageRoute.SignatureLayout} component={Interact2D} />
                            <Route exact path={PageRoute.YourDesign} component={YourDesign} />
                            <Route exact path={PageRoute.MyFinancialPackage} component={MyFinancialPackage} />
                            <Route exact path={PageRoute.MyFinancialCombine} component={MyFinancialCombine} />
                            <Route exact path={PageRoute.MyFinancialBank} component={MyFinancialBank} />
                            <Route exact path={PageRoute.PersonalProfile} component={PersonalProfile} />
                            <Route exact path={PageRoute.MyOrderConfirm} component={MyOrderConfirm} />
                            <Route exact path={PageRoute.MortagePlan} component={MortagePlan} />
                            <Route exact path={PageRoute.ShareSocial} component={Share} />
                          </MainSideBar>
                        </Route>
                      </Switch>
                    </Suspense>
                  </MainLayout>
                </OrderProvider>
              </LayoutProvider>
            </PriceProvider>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
