import { ReactNode } from 'react';
import { TBreadcrumb, LayoutSize } from 'constants/layout';

import Types from './globalTypes';

export type LayoutState = {
  layoutSize?: LayoutSize,
  breadcrumbs?: TBreadcrumb[],
  title?: string | ReactNode,
  noHeader?: boolean,
}

export type GlobalState = {
  layout: LayoutState,
  designChanged?: boolean,
};

const initialState: GlobalState = {
  layout: {},
  designChanged: false,
};

const globalReducer = (state = initialState, action: any): GlobalState => {
  const { data } = action;
  switch (action.type) {
    case Types.SetLayout: {
      const { type, ...layout } = action;
      return {
        ...state,
        layout,
      };
    }

    case Types.SetDesignChanged: {
      return {
        ...state,
        designChanged: data
      }
    }

    default:
      return state;
  }
}

export default globalReducer;