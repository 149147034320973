import { getLangCode } from 'locales';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// ACTIONS
import { CalcPriceParams } from 'apis/oldProjectApis';

// SELECTORS
import {
  selectUserDataLoaded,
  selectUserHome,
} from 'store/userData/userDataSelectors';

// CONSTANTS
import { ECurrency, ELocales, LIST_CURRENCY, TCurrencyObject } from 'constants/currency';
import { TPrices } from 'constants/homeConstants';
import { LocationState } from 'constants/route';
import { StorageKey } from 'constants/storage';
import { useLocation } from 'react-router-dom';
import { setStorage } from 'services/storageService';
import { selectPersonalityId } from 'store/userTaste/userTasteSelectors';

type PriceState = {
  prices: TPrices;
  preferCurrency: ECurrency;
  currencyList: TCurrencyObject[];
  exchangeRate: number;
  layoutHasSelected: boolean;
  localeCode: ELocales;
  needMortgage: boolean;
  readyToCheckOverBudget: boolean;
};

type PriceActions = {
  updatePreferCurrency: (data: ECurrency) => void;
  exchangePrice: (price: number) => number;
  updateCurrencyList: (data: TCurrencyObject[]) => void;
  getCurrency: (code: ECurrency) => TCurrencyObject | undefined;
  setNeedMortgage: React.Dispatch<React.SetStateAction<boolean>>;
  updateSelectionForPrice: (
    selected: Partial<CalcPriceParams> & { isInitiation?: boolean }
  ) => void;
  clearSelectionForPrice: () => void;
  setTempSelectionForPrice: (selected: Partial<CalcPriceParams>) => void;
  updateTempSelectionForPrice: (selected: Partial<CalcPriceParams>) => void;
  clearTempSelectionForPrice: () => void;
};

const initialPrice: TPrices = {
  baseShellPrice: 0,
  fitOutPrice: 0,
  furniturePrice: 0,
  furnitureVatPrice: 0,
  homePrice: 0,
  homeVatPrice: 0,
  totalPrice: 0,
  vatPercentage: 0,
  furnitureDiscountPrice: 0,
};

export const PriceContext = React.createContext<PriceState & PriceActions>({
  prices: { ...initialPrice },
  preferCurrency: ECurrency.Usd,
  currencyList: [],
  exchangeRate: 1,
  layoutHasSelected: false,
  localeCode: ELocales.en,
  needMortgage: false,
  readyToCheckOverBudget: false,
  updatePreferCurrency: () => {},
  exchangePrice: () => 0,
  updateCurrencyList: () => {},
  getCurrency: () => undefined,
  setNeedMortgage: () => {},
  updateSelectionForPrice: () => {},
  clearSelectionForPrice: () => {},
  setTempSelectionForPrice: () => {},
  updateTempSelectionForPrice: () => {},
  clearTempSelectionForPrice: () => {},
});

export const PriceProvider = ({ children }: any) => {
  const { state: locationState } =
    useLocation<Pick<LocationState, 'changingOrder'>>();
  const personalityId = useSelector(selectPersonalityId);

  const localeCode = getLangCode() as ELocales;
  const { projectId, segmentId, floorId, layoutId, productItems } =
    useSelector(selectUserHome);
  const dataLoaded = useSelector(selectUserDataLoaded);
  const [currencyList, setCurrencyList] =
    useState<TCurrencyObject[]>(LIST_CURRENCY);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [prices, setPrices] = useState(initialPrice);
  const [tempPrices, setTempPrices] = useState<TPrices | undefined>();
  const [preferCurrency, setPreferCurrency] = useState<ECurrency>(ECurrency.Usd);
  const [needMortgage, setNeedMortgage] = useState(true);
  const [readyToCheckOverBudget, setReadyToCheckOverBudget] = useState(false);
  const [selected, setSelected] = useState<CalcPriceParams>({});
  const [tempSelected, setTempSelected] = useState<CalcPriceParams>({});

  useEffect(() => {
    if (dataLoaded && personalityId && projectId && floorId && segmentId) {
      setSelected({
        projectId,
        floorId,
        segmentId,
        layoutId: layoutId || undefined,
        productItems: productItems || undefined,
      });
    }
  }, [dataLoaded, personalityId]);

  const updateSelectionForPrice: PriceActions['updateSelectionForPrice'] = (
    selected
  ) => {
    if (selected.isInitiation) {
      if (readyToCheckOverBudget) {
        setReadyToCheckOverBudget(false);
      }
    } else if (!readyToCheckOverBudget) {
      setReadyToCheckOverBudget(true);
    }

    setSelected((prevSelected) => {
      const nextSelected = { ...prevSelected, ...selected };

      // For choosing segment and floor
      if (
        (nextSelected.segmentId !== prevSelected.segmentId ||
          nextSelected.floorId !== prevSelected.floorId) &&
        nextSelected.layoutId
      ) {
        // When deselect segment
        if (nextSelected.segmentId === 0) delete nextSelected.segmentId;

        // Reset previous selections
        delete nextSelected.layoutId;
      }

      // For choosing layout
      if (nextSelected.layoutId !== prevSelected.layoutId) {
        // When de-select layout
        if (nextSelected.layoutId === 0) delete nextSelected.layoutId;

        // Reset previous selections
        if (nextSelected.productItems) delete nextSelected.productItems;
      }

      return nextSelected;
    });
  };

  const setTempSelectionForPrice: PriceActions['setTempSelectionForPrice'] = (
    newTempSelected
  ) => setTempSelected(newTempSelected);

  const updateTempSelectionForPrice: PriceActions['updateTempSelectionForPrice'] =
    (newTempSelected) =>
      setTempSelected((prevState) => ({
        ...prevState,
        ...newTempSelected,
      }));

  const clearSelectionForPrice: PriceActions['clearSelectionForPrice'] = () =>
    setSelected({});

  const clearTempSelectionForPrice: PriceActions['clearTempSelectionForPrice'] =
    () => setTempPrices(undefined);

  // todo: move to global component
  // Handle check price when changing order
  useEffect(() => {
    if (locationState?.changingOrder && personalityId) {
      setTempSelectionForPrice({
        projectId: locationState?.changingOrder.projectId,
        floorId: locationState?.changingOrder.floorId,
        segmentId: locationState?.changingOrder.segmentId,
        layoutId: locationState?.changingOrder.layoutId,
      });
    } else if (tempPrices) {
      clearTempSelectionForPrice();
    }
  }, [locationState?.changingOrder, personalityId]);

  const updateCurrencyList = (data: TCurrencyObject[]) => {
    const rate = data.find((item) => item.code === ECurrency.Vnd);
    setExchangeRate(rate?.conversionRate || 1);
    setCurrencyList([...data]);
  };

  const exchangePrice = (price: number): number => {
    const currencyByCode = currencyList.find(
      (item) => item.code === preferCurrency
    );
    return price * (currencyByCode ? currencyByCode?.conversionRate : 1);
  };

  const updatePreferCurrency = (currencyToUpdate: ECurrency) => {
    setPreferCurrency(currencyToUpdate);
    setStorage(StorageKey.Currency, currencyToUpdate);
  };

  const getCurrency: PriceActions['getCurrency'] = (code) =>
    currencyList.find((currency) => currency.code === code);

  return (
    <PriceContext.Provider
      value={{
        readyToCheckOverBudget,
        prices: tempPrices || prices,
        preferCurrency,
        currencyList,
        exchangeRate,
        layoutHasSelected: !!selected.layoutId,
        localeCode,
        needMortgage,
        updatePreferCurrency,
        exchangePrice,
        getCurrency,
        updateCurrencyList,
        setNeedMortgage,
        updateSelectionForPrice,
        clearSelectionForPrice,
        setTempSelectionForPrice,
        updateTempSelectionForPrice,
        clearTempSelectionForPrice,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
};

export default PriceContext;

export const usePriceContext = () => useContext(PriceContext);
