import { get } from 'lodash';
import { IFilters } from 'components/Project/ProjectFilterBar';
import { TCurrencyObject, ECurrency, ELocales, ELanguageLocales } from 'constants/currency';
import { OrderType } from 'constants/orderConstants';
import { getStorage } from 'services/storageService';
import { StorageKey } from 'constants/storage';
import { CountryPhoneCodeOptionsSortedByName, TranslateCountryOptionAr, TranslateCountryOptionDe, TranslateCountryOptionEl, TranslateCountryOptionEs, TranslateCountryOptionFil, TranslateCountryOptionFr, TranslateCountryOptionIn, TranslateCountryOptionJa, TranslateCountryOptionKo, TranslateCountryOptionMs, TranslateCountryOptionNl, TranslateCountryOptionPl, TranslateCountryOptionPt, TranslateCountryOptionTh, TranslateCountryOptionTl, TranslateCountryOptionZh, TranslateCountryOptionsSortedByName } from 'components/PhoneNumberInput';
import { Lang } from 'constants/i18n';
import { OrderStatus } from 'model/order';

export const getValueByLangCode = (data: any, fieldName: string, langCode = 'en', defaultValue: any = null) => {
  return get(data, `${fieldName}_${langCode}`)
    || get(data, fieldName, defaultValue);
}

type QueryParamsReturn = {
  minBudget: number;
  maxBudget: number;
  numberOfBed?: number;
  viewRating?: number;
  tagIds?: number[];
  facilityIds?: number[];
  currencyCode: string;
}

export const filtersToQueryParams = (filters: IFilters, currency: TCurrencyObject, chatBotCurrency?: ECurrency): QueryParamsReturn => {
  const {
    prices: { value: [min, max] = [] } = {},
    bedNumber: { value: numberOfBed } = {},
    rate: { value: viewRating } = {},
    popularFilters: { tagIds, facilityIds } = {},
  } = filters;


  return {
    minBudget: chatBotCurrency === ECurrency.Usd || currency.code === ECurrency.Usd ? min * currency.conversionRate : min,
    maxBudget: chatBotCurrency === ECurrency.Usd || currency.code === ECurrency.Usd ? max * currency.conversionRate : max,
    numberOfBed,
    viewRating,
    tagIds,
    facilityIds,
    currencyCode: currency.code,
  }
}

export const ChangeOrderType = (orderType: OrderType) => {
  switch (orderType) {
    // 3D
    case OrderType.FINANCIAL_PACKAGE_AND_PERSONALISATION:
      return OrderType.PERSONALISATION;

    // 2D
    case OrderType.FINANCIAL_PACKAGE:
      return OrderType.NORMAL;

    // Bareshell
    case OrderType.BARESHELL_AND_FINANCIAL_PACKAGE:
      return OrderType.BARESHELL;

    default:
      return;
  }
};

export const checkFlowBareShell = (orderType: OrderType) => {
  if ((String(orderType) === String(OrderType.BARESHELL) ||
    String(orderType) === String(OrderType.BARESHELL_AND_FINANCIAL_PACKAGE) ||
    String(orderType) === String(OrderType.WAITING_LIST_BARESHELL))) {
    return true;
  }
  return false;
};

export const checkFlow3D = (orderType: OrderType) => {
  if (String(orderType) === String(OrderType.PERSONALISATION) ||
    String(orderType) === String(OrderType.FINANCIAL_PACKAGE_AND_PERSONALISATION) ||
    String(orderType) === String(OrderType.WAITTING_LIST_PERSONALISATION)) {
    return true;
  }
  return false;
};

export const checkFlow2D = (orderType: OrderType) => {
  if (String(orderType) === String(OrderType.NORMAL) ||
    String(orderType) === String(OrderType.FINANCIAL_PACKAGE) ||
    String(orderType) === String(OrderType.WAITING_LIST)) {
    return true;
  }
  return false;
};

export const checkFlow2D3D = (orderType: OrderType) => {
  return checkFlow2D(orderType) || checkFlow3D(orderType);
}

export const checkFlowWaitingList = (orderType: OrderType) => {
  if (String(orderType) === String(OrderType.WAITING_LIST) ||
    String(orderType) === String(OrderType.WAITING_LIST_BARESHELL) ||
    String(orderType) === String(OrderType.WAITTING_LIST_PERSONALISATION)) {
    return true;
  }
  return false;
}

export const checkFlowFinancial = (orderType: OrderType) => {
  if (orderType === OrderType.FINANCIAL_PACKAGE_AND_PERSONALISATION ||
    orderType === OrderType.FINANCIAL_PACKAGE ||
    orderType === OrderType.BARESHELL_AND_FINANCIAL_PACKAGE) {
    return true;
  }
  return false;
}

export const checkOrderCancel = (orderStatus: OrderStatus) => {
  return orderStatus === OrderStatus.Cancelled
}

export const checkInnerWidth = () => {
  const width = Number(window.innerWidth);
  if (width > 0 && width < 768) {
    return 'sm';
  } else if (width >= 768 && width < 1024) {
    return 'sm';
  } else if (width >= 1024 && width < 1280) {
    return 'sm';
  } else {
    return 'md';
  }
};

export const checkModalWidth = () => {
  const width = Number(window.innerWidth);
  if (width > 0 && width < 768) {
    return 'xl';
  } else if (width >= 768 && width < 1024) {
    return 'lg';
  } else if (width >= 1024 && width < 1280) {
    return 'lg';
  } else {
    return 'lg';
  }
};

export const checkOrderTemp = () => {
  return getStorage(StorageKey.OrderTemp)?.financialPackageId;
};


// "languages": ["en", "vi", "zh", "fr", "ms", "pl", "ar", "pt", "es", "de", "th", "in", "nl", "fil", "el", "ja", "ko", "tl"],

export const formatCountryPhone = (locale: Lang) => {
  switch (locale) {
    case 'vi':
      return TranslateCountryOptionsSortedByName;

    case 'en':
      return CountryPhoneCodeOptionsSortedByName;

    case 'zh':
      return TranslateCountryOptionZh;

    case 'fr':
      return TranslateCountryOptionFr;

    case 'ms':
      return TranslateCountryOptionMs;

    case 'ar':
      return TranslateCountryOptionAr;

    case 'pt':
      return TranslateCountryOptionPt;

    case 'es':
      return TranslateCountryOptionEs;

    case 'de':
      return TranslateCountryOptionDe;

    case 'th':
      return TranslateCountryOptionTh;

    case 'in':
      return TranslateCountryOptionIn;

    case 'nl':
      return TranslateCountryOptionNl;

    case 'fil':
      return TranslateCountryOptionFil;

    case 'el':
      return TranslateCountryOptionEl;

    case 'ja':
      return TranslateCountryOptionJa;

    case 'ko':
      return TranslateCountryOptionKo;

    case 'pl':
      return TranslateCountryOptionPl;

    case 'tl':
      return TranslateCountryOptionTl;

    default:
      return CountryPhoneCodeOptionsSortedByName;
  }
};

export const formatCountryFlex = (locale: Lang) => {
  if (locale === 'vi' || locale === 'ar' || locale === 'th'
    || locale === 'ja' || locale === 'en' || locale === 'ko' || locale === 'zh') {
    return 'row';
  } else {
    return 'column';
  }
};
