import BaseModel from './baseModel';
import ProductCategory from './productCategory';
import { Obj } from 'constants/types';

export enum ProductType {
  Furniture= 'FURNITURE',
  FitOut= 'FIT_OUT',
  Bundle='BUNDLE'
}

class Product extends BaseModel<Product> {
  id?: number;
  productTemplateId?: number;
  productChooseId?: number;
  imsId?: number;
  unrealEngineId?: string; // id to interact with 3D Unreal Engine
  
  category?: ProductCategory;
  type?: ProductType;

  name?: string;
  hsCode?: string;
  description?: string;
  
  imageUrl?: string;
  
  price?: number;
  discountPrice?: number;
  isDiscount?: boolean;
  material?: string;
  color?: string;
  fabric?: string;
  swatchId?: string;
  isChecked?: boolean = false;
  
  // for debug purpose
  levitateName?: string;
  levitateImage?: string;
  imsName?: string;
  imsPrice?: number;

  layoutId?: number;

  fromPayload({
    id,
    productTemplateId,
    unrealEngineId,
    imsId,
    productChooseId,
    category,
    type,
    name,
    levitateHomeName,
    hsCode,
    description,
    imageLevitate,
    image512Url,
    price, // unit price
    finalPrice, // price after BoQ
    finalPriceDiscount,
    material,
    color,
    fabric,
    swatchId,
  }: Obj): this {
    if (id !== undefined) this.id = id;
    if (productTemplateId !== undefined) this.productTemplateId = productTemplateId;
    if (unrealEngineId || imsId) this.unrealEngineId = unrealEngineId || imsId;
    if (imsId !== undefined) this.imsId = imsId;
    if (productChooseId !== undefined) this.productChooseId = productChooseId;
    if (category !== undefined) this.category = new ProductCategory().fromPayload(category);
    if (type !== undefined) this.type = type;
    this.name = levitateHomeName || name;
    if (hsCode !== undefined) this.hsCode = hsCode;
    if (description !== undefined) this.description = description;
    this.imageUrl = imageLevitate || image512Url;
    if (finalPrice !== undefined) {
      this.price = finalPrice;
    } else if (price !== undefined) {
      this.price = price;
    }
    if (finalPriceDiscount !== undefined && finalPriceDiscount !== null) {
      this.discountPrice = finalPriceDiscount;
      if (this.price && finalPriceDiscount < this.price) {
        this.isDiscount = true;
      }
    }
    if (material !== undefined) this.material = material;
    if (color !== undefined) this.color = color;
    if (fabric !== undefined) this.fabric = fabric;
    if (swatchId !== undefined) this.swatchId = swatchId;
    
    this.imsName = name;
    this.levitateName = levitateHomeName;
    this.imsPrice = price;
    this.levitateImage = imageLevitate;
    
    return this;
  }
  
  fromProductPayload({
    id, // productChooseId
    productId, // same as id but different API
    productName, // same as name but different API
    levitateHomeName,
    unrealEngineId,
    productImsId,
    type,
    imageLevitate,
    image512Url,
    price, // unit price
    finalPrice, // price after BoQ
    finalPriceDiscount,
    material,
    color,
    fabric,
    description,
    swatchId,
  }: Obj): this {
    if (id !== undefined) this.productChooseId = id;
    if (productId !== undefined) this.id = productId;
    this.name = levitateHomeName || productName;
    if (unrealEngineId || productImsId) this.unrealEngineId = unrealEngineId || productImsId;
    if (productImsId !== undefined) this.imsId = productImsId;
    if (type !== undefined) this.type = type;
    this.imageUrl = imageLevitate || image512Url;
    if (finalPrice || finalPrice === 0) {
      this.price = finalPrice;
    } else if (price || price === 0) {
      this.price = price;
    }else{
      this.price = 0;
    }
    if (finalPriceDiscount !== undefined && finalPriceDiscount !== null) {
      this.discountPrice = finalPriceDiscount;
      if (this.price && finalPriceDiscount < this.price) {
        this.isDiscount = true;
      }
    }
    if (material !== undefined) this.material = material;
    if (color !== undefined) this.color = color;
    if (fabric !== undefined) this.fabric = fabric;
    if (description !== undefined) this.description = description;
    if (swatchId !== undefined) this.swatchId = swatchId;
  
    this.imsName = productName;
    this.levitateName = levitateHomeName;
    this.imsPrice = price;
    this.levitateImage = imageLevitate;

    return this;
  }
}

export default Product;
