import { cloneDeep, forEach, isEmpty, mapValues, pick, pickBy } from 'lodash';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// APIS
import { fetchDefaultProductsApi } from 'apis/designApis';

// HOOKS
import { usePriceContext } from 'contexts/priceContext';
import { useLoadingCallback, useRoute } from 'hooks';
import useAbortRequest from 'hooks/useAbortRequest';
import { useDispatch } from 'react-redux';

// MODELS
import Layout, { LayoutCamera } from 'model/layout';
import Product from 'model/product';
import ProductCategory from 'model/productCategory';
import ProductDefault from 'model/productDefault';
import SavedDesign from 'model/savedDesign';
import Segment from 'model/segment';

// CONSTANTS
import { THomeData } from 'constants/designConstants';
import { StorageKey } from 'constants/storage';
import { Obj } from 'constants/types';
import { getStorage, setStorage } from 'services/storageService';
import { setDesignHome } from 'store/designHome/designHomeActions';

export enum InteractionTab {
  MyLayouts = 'my-layouts',
  MyDesigns = 'my-designs',
  MyFavourites = 'my-favourites',
}

export enum TypeLoadDesign {
  ChangeDesign = 'ChangeDesign',
  ChangeLayout = 'ChangeLayout',
}

type DesignState = {
  activeTab: InteractionTab;
  selectedPlaceholderId?: number;
  categoriesById: Record<number, ProductCategory>;
  storeProductsByPlaceholderId: Record<number, Product | null>; // temporarily select product list
  productsByPlaceholderId: Record<number, Product | null>; // current product list
  productDefaultByPlaceholderId: Record<number, ProductDefault>;
  initProductDefaultByPlaceholderId: Record<number, ProductDefault>;
  quantityByPlaceholderId: Record<number, number>;
  selectedPersonalityId: number;
  oldSelectedLayoutId: number;
  selectedCategoryId: number;
  selectedProductId: number;
  selectedCamera?: LayoutCamera;
  categoryList?: ProductCategory[];
  productList: Product[];
  productListPageNumber: number;
  productListPageCount: number;
  savedDesigns: SavedDesign[];
  layout?: Layout;
  segment?: Segment;
  layoutList?: Layout[];
  showSignupLight: boolean;
  replacedProducts: Record<number, Product | null>;
  isLoadDesign?: boolean;

  // 3D Arcware
  selectedProductIdByClick: number;
  selectedDesignId: number,
};

type Placeholder = Pick<ProductDefault, 'placeholderId' | 'placeholderName' | 'isFitOut' | 'removable'>;

type PlaceholderData = {
  placeholder: Placeholder;
  product: Product | null;
}

type DesignMemo = {
  homeData: THomeData;
  changingOrderId?: number;
  selectedCategory?: ProductCategory;
  selectedProduct?: Product;
  isFurnitureRemoved: boolean;
  currentSelectedData?: {
    selectablePlaceholders: PlaceholderData[];
    placeholder?: Placeholder;
    product?: Product;
  };
}

const initState: DesignState = {
  activeTab: InteractionTab.MyLayouts,
  selectedPlaceholderId: 0,
  categoriesById: {},
  storeProductsByPlaceholderId: {},
  productsByPlaceholderId: {},
  initProductDefaultByPlaceholderId: {},
  productDefaultByPlaceholderId: {},
  quantityByPlaceholderId: {},
  selectedPersonalityId: 0,
  oldSelectedLayoutId: 0,
  selectedCategoryId: 0,
  selectedProductId: 0,
  productList: [],
  productListPageNumber: 0,
  productListPageCount: 0,
  savedDesigns: [],
  categoryList: undefined,
  layoutList: [],
  showSignupLight: false,
  replacedProducts: {},
  isLoadDesign: false,

  // 3D Arcware
  selectedProductIdByClick: 0,
  selectedDesignId: 0,
};

type TDesignAction = {
  initialLayout3D: (quantity: Record<number, number>) => void;
  changeSelectedPlaceholderId3D: (placeholderId: number) => void;
  updateDesignContext: (newState: Partial<DesignState>) => void;
  resetDesignContext: (resetPropNames?: keyof DesignState | (keyof DesignState)[]) => void;
  loadDesign: (
    personalityId: number,
    replacedDefaultList?: ProductDefault[],
    selectedDesignId?: number,
  ) => void;
  changeSelectedCategoryId: (categoryId: number, keepSelectedPlaceholderId?: boolean) => void;
  changeSelectedCamera: (camera: LayoutCamera) => void;
  changeCurrentProduct: (placeholderId: number, product: Product | null) => void;
  updateStoreProductByPlaceholderId: (placeholderId: number, product: Product | null) => void;
  toggleRemoveAllProducts: () => Record<number, number>;
};

export const DesignContext = React.createContext<DesignState & DesignMemo & TDesignAction>({
  ...initState,
  homeData: {} as THomeData,
  isFurnitureRemoved: false,
  initialLayout3D: () => { },
  changeSelectedPlaceholderId3D: () => { },
  updateDesignContext: () => { },
  resetDesignContext: () => { },
  loadDesign: () => { },
  changeSelectedCategoryId: () => { },
  changeSelectedCamera: () => { },
  changeCurrentProduct: () => { },
  updateStoreProductByPlaceholderId: () => { },
  toggleRemoveAllProducts: () => ({}),
});

export const useDesignContext = () => useContext(DesignContext);

type Props = PropsWithChildren<{
  userPersonalityId: number; // personality as result of lavitaste questionnaire
  changingOrderId?: number;
  fitOutOnly?: boolean;
  homeData: THomeData;
}>;

export const DesignProvider = ({
  userPersonalityId,
  changingOrderId,
  homeData,
  fitOutOnly,
  children,
}: Props) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const [state, setState] = useState<DesignState>(() => ({
    ...initState,
    selectedPersonalityId: userPersonalityId,
  }));
  const { preferCurrency } = usePriceContext()

  const { params } = useRoute();
  const { personalityId } = params as {
    personalityId: number;
  };

  const [savedResetProductsByPlaceholderId, setSavedResetProductByPlaceholderId] =
    useState<DesignState['productsByPlaceholderId'] | undefined>()
  const dispatch = useDispatch();

  const updateDesignContext = useCallback(
    (newState: Partial<DesignState>) =>
      setState((prevState) => ({
        ...prevState,
        ...newState,
      })),
    []
  );

  const changeSelectedCamera = useCallback((camera: LayoutCamera) => {
    updateDesignContext({
      selectedCamera: camera,
    });
  }, []);

  const changeSelectedCategoryId = useCallback((selectedCategoryId: number, keepSelectedPlaceholderId = false) => {
    if (selectedCategoryId === 0 && !keepSelectedPlaceholderId) {
      setStorage(StorageKey.SelectedPlaceholderId, 0);
    }
    updateDesignContext({
      selectedCategoryId,
      productList: [],
      productListPageNumber: 0,
      productListPageCount: 0,
      selectedProductId: 0,
      ...((selectedCategoryId === 0 && !keepSelectedPlaceholderId) ? { selectedPlaceholderId: 0 } : {}),
    })
  }, []);

  const changeCurrentProduct: TDesignAction['changeCurrentProduct'] = useCallback(
    (placeholderId, product) => setState(prevState => {
      if (prevState.productsByPlaceholderId[placeholderId] === undefined) {
        return prevState;
      }

      let replacedProducts: Record<number, Product | null> = { ...prevState.replacedProducts, [Number(placeholderId)]: { ...product, layoutId: prevState?.layout?.id } as Product || null };
      let newReplacedProducts: Record<number, Product | null> = { ...prevState.replacedProducts };
      Object.keys(replacedProducts).filter(productReplace => {
        const isValue = Object.keys(prevState.initProductDefaultByPlaceholderId).some(item => {
          return prevState.initProductDefaultByPlaceholderId[Number(item)].product?.id === replacedProducts[Number(productReplace)]?.id &&
            Number(replacedProducts[Number(productReplace)]?.layoutId) === Number(prevState?.layout?.id)
        })
        if (!isValue) {
          newReplacedProducts[Number(productReplace)] = { ...replacedProducts[Number(productReplace)], layoutId: prevState?.layout?.id } as Product || null;
        }
      });

      const newProductDefaultByPlaceholderId = {
        ...prevState.productDefaultByPlaceholderId
      };
      newProductDefaultByPlaceholderId[placeholderId].product = product ? product : undefined;
      setStorage(StorageKey.ProductDefaultByPlaceholderId, newProductDefaultByPlaceholderId);

      return {
        ...prevState,
        productsByPlaceholderId: {
          ...prevState.productsByPlaceholderId,
          [placeholderId]: product,
        },
        productDefaultByPlaceholderId: {
          ...newProductDefaultByPlaceholderId
        },
        replacedProducts: newReplacedProducts,
      }
    }),
    []
  );

  const updateStoreProductByPlaceholderId: TDesignAction['updateStoreProductByPlaceholderId'] = useCallback(
    (placeholderId, product) => setState(prevState => {
      return {
        ...prevState,
        storeProductsByPlaceholderId: {
          ...prevState.storeProductsByPlaceholderId,
          [placeholderId]: product,
        }
      }
    }),
    []
  );

  const toggleRemoveAllProducts: TDesignAction['toggleRemoveAllProducts'] = useCallback(
    () => {
      // Remove all furniture
      if (!savedResetProductsByPlaceholderId) {
        const productsByPlaceholderId = cloneDeep(
          pickBy(
            state.productsByPlaceholderId,
            (product, placeholderId) =>
              !state.productDefaultByPlaceholderId[+placeholderId]?.isFitOut
          )
        );
        setSavedResetProductByPlaceholderId(productsByPlaceholderId);
        updateDesignContext({
          productsByPlaceholderId: {
            ...mapValues(productsByPlaceholderId, () => null)
          },
        });
        return mapValues(productsByPlaceholderId, () => 0);
      }
      // Restore all removed furniture
      else {
        const productsByPlaceholderId = cloneDeep(savedResetProductsByPlaceholderId);
        updateDesignContext({
          productsByPlaceholderId: productsByPlaceholderId,
        });
        setSavedResetProductByPlaceholderId(undefined);
        return mapValues(productsByPlaceholderId, product => product?.id || 0)
      }
    },
    [
      savedResetProductsByPlaceholderId,
      state.productsByPlaceholderId,
      state.productDefaultByPlaceholderId,
    ]
  );

  const loadDesign = useCallback(async (layoutId, replacedDefaultList, selectedDesignId) => {
    if (layoutId) {
      const newState: Partial<DesignState> = {
        selectedPersonalityId: layoutId,
      };
      let productDefaultByPlaceholderId: DesignState['productDefaultByPlaceholderId'] = {};

      if (
        layoutId !== state.oldSelectedLayoutId ||
        isEmpty(state.productsByPlaceholderId)
      ) {
        await abortRequest();
        const { data } = await fetchDefaultProductsApi({
          projectId: homeData.projectId,
          layoutId: Number(layoutId),
          personalityId: personalityId,
          currencyCode: preferCurrency,
        }, { newAbortSignal });
        let productDefaultList: ProductDefault[] = (data || []).map(
          (productDefaultPayload: Obj) => new ProductDefault().fromPayload(productDefaultPayload)
        )

        // todo: when interact with 3D should not include furniture items
        if (fitOutOnly) {
          productDefaultList = productDefaultList.map(productDefault => {
            if (!productDefault.isFitOut) {
              productDefault.product = undefined;
            }
            return productDefault;
          });
        }

        productDefaultList.forEach(productDefault => {
          productDefaultByPlaceholderId[productDefault.placeholderId as number] = productDefault;
        })

        newState.productDefaultByPlaceholderId = productDefaultByPlaceholderId;

      } else {
        productDefaultByPlaceholderId = cloneDeep(state.productDefaultByPlaceholderId);
      }

      if (!replacedDefaultList) {
        newState.productsByPlaceholderId = mapValues(
          productDefaultByPlaceholderId,
          productDefault => productDefault.product || null
        );
      } else {
        const productsById: Record<number, Product | null> = {};
        (replacedDefaultList || []).forEach((productPayload: Obj) => {
          console.log(productPayload);
          productsById[productPayload?.product?.id] = new Product().fromPayload(productPayload?.product)
        })

        newState.productsByPlaceholderId = mapValues(
          productDefaultByPlaceholderId,
          (productDefault) => {
            const productChoosing = replacedDefaultList.find(
              (choosing: any) => choosing?.placeholderId == productDefault?.placeholderId
            );

            if (!productChoosing) return productDefault?.product || null;

            return productsById[productChoosing?.product?.id || 0] || null;
          }
        );

        // todo: Quick-way To trigger reload new items to 3D
        newState.productDefaultByPlaceholderId = mapValues(
          productDefaultByPlaceholderId,
          (productDefault) => {
            const productChoosing = replacedDefaultList.find(
              (choosing: any) => choosing.placeholderId == productDefault?.placeholderId
            );
            if (!productChoosing) {
              productDefault.product = productDefault?.product;
            } else {
              productDefault.product = productsById[productChoosing?.product?.id || 0] || undefined;
            }
            return productDefault;
          }
        )
      }
      newState.initProductDefaultByPlaceholderId = { ...productDefaultByPlaceholderId }

      const findLayoutById = state.layoutList?.find((item: Layout) => Number(item.id) === Number(layoutId));

      if (findLayoutById?.id) {
        const findCamera = findLayoutById?.cameras?.find((item: LayoutCamera) => Number(item.id) === Number(state.selectedCamera?.id))
        if (!findCamera) {
          if (findLayoutById?.cameras && findLayoutById?.cameras.length > 0) {
            newState.selectedCamera = findLayoutById?.cameras[0];
          }
        }
        dispatch(setDesignHome({ layoutId: layoutId, layoutName: findLayoutById?.name }))
        setStorage(StorageKey.StepYourDesign, { ...getStorage(StorageKey.StepYourDesign), layoutId: layoutId, layoutName: findLayoutById?.name });
        newState.layout = findLayoutById;
      }

      setStorage(StorageKey.ProductDefaultByPlaceholderId, { ...newState.productDefaultByPlaceholderId });
      updateDesignContext({
        ...newState,
        isLoadDesign: false,
        selectedDesignId: selectedDesignId || 0,
        // oldSelectedLayoutId: layoutId,
      });
    }
  }, [
    state.oldSelectedLayoutId,
  ])

  // Initiate design data or change personal style
  const resetDesignContext = useCallback(
    (resetPropNames?: string | string[]) => {
      let toResetContext: DesignState = { ...initState };

      if (resetPropNames) {
        // @ts-ignore
        toResetContext = pick(toResetContext, resetPropNames);
      }
      updateDesignContext(toResetContext);
    },
    []
  );

  // Initial Layout Product Default from 3d Arcware
  const initialLayout3D = useCallback((quantity: Record<number, number>) => {
    updateDesignContext({
      quantityByPlaceholderId: quantity,
    });
  }, []);

  // Change Selected Product Default from 3d Arcware
  const changeSelectedPlaceholderId3D = useCallback((selectedPlaceholderId: number) => {
    let oldSelectedPlaceholderId = getStorage(StorageKey.SelectedPlaceholderId) || 0;
    if (selectedPlaceholderId !== oldSelectedPlaceholderId) {
      let productDefaultByPlaceholderId = getStorage(StorageKey.ProductDefaultByPlaceholderId) || {};
      let categoriesById = getStorage(StorageKey.CategoriesById) || {};
      setStorage(StorageKey.SelectedPlaceholderId, selectedPlaceholderId);

      const newState: Partial<DesignState> = {
        selectedPlaceholderId,
        activeTab: InteractionTab.MyDesigns,
        productList: [],
        productListPageNumber: 0,
        productListPageCount: 0,
        selectedProductId: 0,
      };

      if (productDefaultByPlaceholderId?.[selectedPlaceholderId]?.categoryIds) {
        const { categoryIds = [] } = productDefaultByPlaceholderId?.[selectedPlaceholderId];

        if (categoryIds.length === 1) {
          newState.selectedCategoryId = categoryIds[0];
        } else {
          newState.selectedCategoryId = 0;
          newState.categoryList = categoryIds.map((catId: any) => categoriesById[catId]);
        }
      }

      updateDesignContext(newState);
    }
  }, []);

  const categoryIdPlaceholdersMap: Record<number, PlaceholderData[]> = useMemo(() => {
    if (isEmpty(state.productDefaultByPlaceholderId)) return {};

    const categoryIdPlaceholderIdsMap: Record<number, number[]> = {};

    Object.values(state.productDefaultByPlaceholderId).forEach(productDefault => {
      if (!productDefault.categoryIds) return;

      productDefault.categoryIds.forEach(categoryId => {
        if (!categoryIdPlaceholderIdsMap[categoryId]) {
          categoryIdPlaceholderIdsMap[categoryId] = [];
        }
        categoryIdPlaceholderIdsMap[categoryId].push(productDefault.placeholderId!);
      })
    });

    return mapValues(categoryIdPlaceholderIdsMap, placeholderIds => placeholderIds.map(
      placeholderId => ({
        placeholder: pick(
          state.productDefaultByPlaceholderId[placeholderId],
          ['placeholderName', 'placeholderId', 'isFitOut', 'removable']
        ),
        product: state.productsByPlaceholderId[placeholderId],
      })
    ));
  }, [state.productDefaultByPlaceholderId, state.productsByPlaceholderId]);

  const currentSelectedData: DesignMemo['currentSelectedData'] = useMemo(() => {
    if (!state.selectedCategoryId || !categoryIdPlaceholdersMap[state.selectedCategoryId])
      return undefined;

    const nextCurrentPlaceholdersData: DesignMemo['currentSelectedData'] = {
      selectablePlaceholders: categoryIdPlaceholdersMap[state.selectedCategoryId],
    }

    if (state.selectedPlaceholderId) {
      const placeholderData = nextCurrentPlaceholdersData.selectablePlaceholders.find(
        ({ placeholder: { placeholderId } }) => placeholderId === state.selectedPlaceholderId
      );
      if (placeholderData) {
        Object.assign(nextCurrentPlaceholdersData, placeholderData);
      }
    } else if (categoryIdPlaceholdersMap[state.selectedCategoryId].length === 1) {
      Object.assign(nextCurrentPlaceholdersData, categoryIdPlaceholdersMap[state.selectedCategoryId][0]);
    }

    return nextCurrentPlaceholdersData;
  }, [categoryIdPlaceholdersMap, state.selectedCategoryId, state.selectedPlaceholderId]);

  const selectedCategory: DesignMemo['selectedCategory'] = useMemo(() => {
    if (!state.selectedCategoryId) return undefined;

    return Object.values(state.categoriesById).find(
      ({ id }) => id === state.selectedCategoryId
    );
  }, [state.selectedCategoryId, state.categoriesById]);

  const selectedProduct: DesignMemo['selectedProduct'] = useMemo(() => {
    if (!state.selectedProductId) return undefined;

    return state.productList.find(({ id }) => id === state.selectedProductId);
  }, [state.selectedProductId, state.productList]);


  const value = useMemo(
    () => ({
      ...state,
      changingOrderId,
      homeData,
      selectedCategory,
      selectedProduct,
      currentSelectedData,
      isFurnitureRemoved: !!savedResetProductsByPlaceholderId,
      initialLayout3D,
      changeSelectedPlaceholderId3D,
      updateDesignContext,
      resetDesignContext,
      loadDesign,
      changeSelectedCategoryId,
      changeSelectedCamera,
      changeCurrentProduct,
      updateStoreProductByPlaceholderId,
      toggleRemoveAllProducts,
    }),
    [state, selectedCategory, savedResetProductsByPlaceholderId]
  );

  // For debug purpose
  useEffect(() => {
    console.debug('Design context changed', value);
  }, [value]);

  return (
    <DesignContext.Provider value={value}>{children}</DesignContext.Provider>
  );
};
