import React from 'react';

const IcNavShow = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_667_6313)">
        <path
          d="M1.67972 0.570312H23.1992C23.7515 0.570312 24.1992 1.01803 24.1992 1.57031C24.1992 2.1226 23.7515 2.57031 23.1992 2.57031H1.67972C1.12744 2.57031 0.67972 2.1226 0.67972 1.57031C0.67972 1.01803 1.12744 0.570312 1.67972 0.570312Z"
          fill="#C9C8CC"
        />
        <path
          d="M1.67964 7.57031H12.6484C13.2007 7.57031 13.6484 8.01803 13.6484 8.57031C13.6484 9.1226 13.2007 9.57031 12.6484 9.57031H1.67964C1.12735 9.57031 0.679637 9.1226 0.679637 8.57031C0.679637 8.01803 1.12735 7.57031 1.67964 7.57031Z"
          fill="#C9C8CC"
        />
        <path
          d="M1.67964 14.5703H12.6484C13.2007 14.5703 13.6484 15.018 13.6484 15.5703C13.6484 16.1226 13.2007 16.5703 12.6484 16.5703H1.67964C1.12735 16.5703 0.679637 16.1226 0.679637 15.5703C0.679637 15.018 1.12735 14.5703 1.67964 14.5703Z"
          fill="#C9C8CC"
        />
        <path
          d="M1.67972 21.5703H23.1992C23.7515 21.5703 24.1992 22.018 24.1992 22.5703C24.1992 23.1226 23.7515 23.5703 23.1992 23.5703H1.67972C1.12744 23.5703 0.67972 23.1226 0.67972 22.5703C0.67972 22.018 1.12744 21.5703 1.67972 21.5703Z"
          fill="#C9C8CC"
        />
        <path
          d="M23.304 12.7666C23.7733 12.3672 23.7733 11.6426 23.304 11.2433L19.4576 7.97075C18.8082 7.41825 17.8096 7.87977 17.8096 8.73239V15.2774C17.8096 16.13 18.8082 16.5916 19.4576 16.0391L23.304 12.7666Z"
          fill="#C9C8CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_667_6313">
          <rect
            width="25"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 25 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcNavShow;
