import React, { useEffect, useState } from 'react';

// COMPONENTS
import Stack from '@mui/material/Stack';
import Select from 'components/inputs/Select';

// SERVICES
import { getStorage } from 'services/storageService';

// CONSTANTS
import { SelectChangeEvent } from '@mui/material';
import { StorageKey } from 'constants/storage';
import { LIST_CURRENCY } from 'constants/currency';
import { SelectOption } from 'constants/types';
import { usePriceContext } from 'contexts/priceContext';

const CURRENCY_OPTIONS: SelectOption[] = LIST_CURRENCY.map((currency) => ({
  label: (
    <Stack direction="row" gap={1} alignItems="center">
      {currency.icon} {currency.code}
    </Stack>
  ),
  value: currency.id,
}));

const CurrencyDropdown = () => {
  const [currencyValue, setCurrencyValue] = useState<number>(1);

  const { updatePreferCurrency, updateCurrencyList } = usePriceContext();

  useEffect(() => {
    updateCurrencyList(LIST_CURRENCY);

    const currencyCode = getStorage(StorageKey.Currency);
    const currency = currencyCode
      ? LIST_CURRENCY.find(({ code }) => code === currencyCode)
      : LIST_CURRENCY[0];

    if (currency) {
      setCurrencyValue(currency.id);
      updatePreferCurrency(currency.code);
    }
  }, []);

  const handleSelectCurrency = (e: SelectChangeEvent<unknown>) => {
    const { value: id } = e.target;
    const currency = LIST_CURRENCY.find((item) => item.id === id);
    setCurrencyValue(currency?.id!);
    updatePreferCurrency(currency?.code!);
    window.location.reload();
  };

  return (
    <Select
      options={CURRENCY_OPTIONS}
      value={currencyValue || CURRENCY_OPTIONS[0].value}
      hideSelected
      fullWidth
      required
      margin="dense"
      onChange={handleSelectCurrency}
    />
  );
};

export default CurrencyDropdown;
