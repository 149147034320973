import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import cl from 'classnames';

// COMPONENTS
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// ICONS
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

// CONSTANTS
import { SelectOption } from 'constants/types';
import { useTheme } from '@mui/material';

export type SelectProps = MuiSelectProps & {
  options?: SelectOption[];
  getOptionDisabled?: (option: SelectOption) => boolean;
  fullWidth?: FormControlProps['fullWidth'];
  width?: number;
  height?: string;
  helperText?: string;
  noneLabel?: string;
  noneValue?: '' | 0;
  required?: boolean;
  dark?: boolean;
  hideSelected?: boolean;
};

const Select = ({
  id,
  label,
  options,
  value,
  getOptionDisabled,
  fullWidth,
  helperText,
  noneLabel = 'None',
  noneValue,
  displayEmpty,
  error,
  disabled,
  required,
  dark,
  width,
  height = 'auto',
  hideSelected,
  sx,
  ...restProps
}: SelectProps) => {
  const theme = useTheme(); 

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      focused={displayEmpty ? true : undefined}
      // className={cl({ 'bgcolor-dark': dark })}
      hiddenLabel={!label}
    >
      {label && <InputLabel id={id}>{label}</InputLabel>}
      <MuiSelect
        id={id}
        label={label}
        value={value}
        disabled={disabled || isEmpty(options)}
        IconComponent={ExpandMoreOutlinedIcon}
        error={error}
        displayEmpty={displayEmpty}
        MenuProps={{
          disableScrollLock: true,
          marginThreshold: 0,
          PaperProps: {
            elevation: 2,
            sx: {
              '&': {
                backgroundColor: theme.palette?.default?.dark as string | '#4E4B55',
                height: height,
                width,
                ...(restProps.startAdornment
                  ? {
                      ml: -2.5,
                      py: 1,
                    }
                  : {}),
              },
              '.MuiList-padding': {
                padding: 0,
              },
              '& .MuiMenuItem-root': {
                py: 1,
                px: 2,
                fontSize: 14,
              },
            },
          },
        }}
        sx={{ ...sx, width }}
        {...restProps}
      >
        {!required && (
          <MenuItem value={noneValue}>
            <em>{noneLabel}</em>
          </MenuItem>
        )}
        {options
          ? options.map((option) => (
              <MenuItem
                hidden={hideSelected && option.value === value}
                key={option.value}
                value={option.value}
                disabled={
                  getOptionDisabled ? getOptionDisabled(option) : undefined
                }
              >
                {option.label ?? option.value}
              </MenuItem>
            ))
          : null}
      </MuiSelect>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)`
  && {

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${p => p.theme?.palette?.primary?.contrastText} !important;
    }

    .MuiInputBase-formControl {
      .MuiInputBase-input {
        padding: 10px;
      }
    }

    @media only screen and (max-width: 992px) {
      .MuiOutlinedInput-root {
        font-size: 13px;
      }
    }

    label {
      font-size: 13px;
      color: #aca8b7;

      &.MuiInputLabel-outlined {
        top: -3px;
      }

      &.MuiInputLabel-shrink {
        top: 0;
        color: #aca8b7;
      }
    }

    fieldset {
      border-width: 1px !important;
      border-radius: 0 !important ;
    }

    .Mui-disabled {
      background-color: rgb(220 220 220 / 20%) !important;
    }

    .Mui-error {
      color: ${(p) => p.theme.palette.error.main} !important;
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(p) => p.theme.palette.error.main} !important;
      }
    }

    .MuiFormHelperText-root {
      margin-left: 0;
    }

    .MuiInputBase-hiddenLabel {
      fieldset {
        top: 0;
      }
      legend {
        display: none;
      }
    }

    &.bgcolor-dark {
      background-color: #2f2c37;

      .Mui-disabled {
        background-color: #2f2c37 !important;
      }
    }
  }

  &.MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .MuiInputBase-adornedStart {
    .MuiSvgIcon-root:first-child {
      width: 24px;
      padding-right: 4px;
      border-right: 1px solid ${p => p.theme.palette?.primary?.contrastText};
      //transform: translateX(-6px);
    }

    .MuiSelect-select {
      //margin-left: 4px;
      //border-left: 1px solid #5f5a69;
    }
  }
`;

export default Select;
