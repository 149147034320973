import { i18n } from 'locales';
import { ECurrency } from './currency';

export type TPrices = {
  baseShellPrice: number;
  fitOutPrice: number;
  furniturePrice: number;
  furnitureVatPrice: number;
  homePrice: number;
  homeVatPrice: number;
  totalPrice: number;
  vatPercentage: number;
  furnitureDiscountPrice: number;
};

export type NavbarPrice = {
  blockId: number;
  floorId: number;
  bareshellPrice: number;
  fitoutPrice: number;
  furniturePrice: number;
  totalVatPrice: number;
  vat: number;
  totalPrice: number;
  vatPrice: number;
}

// ALL, STUDIO, ONEBEDROOM, TWOBEDROOMS, THREEBEDROOMS
export enum FilterRoomType {
  Empty = '',
  All = 'ALL',
  Studio = 'STUDIO',
  OneBedroom = 'ONEBEDROOM',
  TwoBedrooms = 'TWOBEDROOMS',
  ThreeBedrooms = 'THREEBEDROOMS',
}

export const LOCATIONS_FIXTURE: Record<
  string,
  { description: string; flagUrl: string; youtubeId?: string }
> = {
  'vietnam': {
    description: i18n.t(
      'messages|location:Vietnam:description',
      "Vietnam a country of contrasts. With a population of over 97 million, it's the 15th most populous in the world. Its history and culture are a blend of influences, making it a unique and fascinating destination. From breathtaking natural landscapes to a booming economy, Vietnam is a country on the rise."
    ),
    youtubeId: '6-UH_M1zxaU', // https://www.youtube.com/watch?v=6-UH_M1zxaU
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_vn.webp',
  },
  'poland': {
    description: i18n.t(
      'messages|location:Poland:description',
      "Poland a country with a rich history and vibrant culture. With a population of almost 38 million, it's the 6th most populous in the European Union. From the medieval architecture of Krakow to the stunning Tatra Mountains, Poland offers a diverse range of experiences. Its economy is growing rapidly, with a focus on technology and innovation. Come for the pierogi, stay for the hospitality - Poland is a hidden gem waiting to be discovered."
    ),
    youtubeId: 'CrKdc3XGDOc',
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_poland.webp',
  },
  'indonesia': {
    description: i18n.t(
      'messages|location:Indonesia:description',
      "Indonesia a land of contrasts. With over 17,000 islands and a population of over 270 million, it's the world's largest island country. Its culture is a blend of indigenous, Indian, Chinese, and European influences. From the beaches of Bali to the jungles of Sumatra, Indonesia offers a wealth of natural beauty. Its economy is growing rapidly, with a focus on manufacturing and tourism. Come for the adventure, stay for the warmth - Indonesia is a destination like no other."
    ),
    youtubeId: 'RUcdo617_Co',
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_indonesia.webp',
  },
  'netherlands': {
    description: i18n.t(
      'messages|location:Netherlands:description',
      "Netherlands a small country with a big impact. With a population of just over 17 million, it's one of the most densely populated countries in the world. Its history is rich, with a legacy of art, innovation, and trade. From the canals of Amsterdam to the tulip fields of Lisse, Netherlands offers a unique blend of old-world charm and modern sophistication. Its economy is diverse, with a focus on technology and agriculture. Come for the cheese, stay for the culture - Netherlands is a country that punches above its weight."
    ),
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_netherlands.webp',
  },
  'brazil': {
    description: i18n.t(
      'messages|location:Brazil:description',
      "Brazil a country of contrasts. With a population of over 211 million, it's the 5th most populous in the world. Its culture is a blend of indigenous, African, and European influences. From the beaches of Rio de Janeiro to the Amazon rainforest, Brazil offers a diverse range of experiences. Its economy is the largest in Latin America, with a focus on agriculture, mining, and manufacturing. Come for the samba, stay for the hospitality - Brazil is a country that will capture your heart."
    ),
    youtubeId: 'tG20DR0vDDA',
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_brazil.webp',
  },
  'mexico': {
    description: i18n.t(
      'messages|location:Mexico:description',
      "Mexico a country of color and flavor. With a population of over 129 million, it's the 10th most populous in the world. Its culture is a blend of indigenous, Spanish, and African influences. From the ancient ruins of Teotihuacan to the beaches of Cancun, Mexico offers a wealth of history and natural beauty. Its economy is the 2nd largest in Latin America, with a focus on manufacturing, tourism, and oil. Come for the tacos, stay for the fiesta - Mexico is a country that will leave you enchanted."
    ),
    youtubeId: 'sH4uierF0Lc',
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_mexico.webp',
  },
  'colombia': {
    description: i18n.t(
      'messages|location:Colombia:description',
      "Colombia a country of passion and diversity. With a population of over 50 million, it's the 3rd most populous in Latin America. Its culture is a blend of indigenous, African, and Spanish influences. From the colorful streets of Cartagena to the lush coffee plantations of the Andes, Colombia offers a unique blend of history and natural beauty. Its economy is growing rapidly, with a focus on oil, mining, and tourism. Come for the empanadas, stay for the warmth - Colombia is a country that will steal your heart."
    ),
    youtubeId: 'wktLtcbXrcM',
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_colombia.webp',
  },
  'egypt': {
    description: i18n.t(
      'messages|location:Egypt:description',
      "Egypt a land of ancient wonders. With a population of over 100 million, it's the most populous country in the Arab world. Its history is rich, with a legacy of pharaohs, pyramids, and temples. From the Nile River to the Red Sea, Egypt offers a wealth of natural beauty. Its economy is growing rapidly, with a focus on tourism, oil, and gas. Come for the history, stay for the hospitality - Egypt is a country that will leave you in awe."
    ),
    youtubeId: 'BapSQFJPMM0',
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_egypt.webp',
  },
  'malaysia': {
    description: i18n.t(
      'messages|location:Malaysia:description',
      "Malaysia a country of diversity and contrasts. With a population of over 32 million, it's a melting pot of cultures and religions. Its history is rich, with a legacy of trade and colonization. From the Petronas Twin Towers to the beaches of Langkawi, Malaysia offers a unique blend of modernity and natural beauty. Its economy is growing rapidly, with a focus on manufacturing, tourism, and oil. Come for the nasi lemak, stay for the hospitality - Malaysia is a country that will surprise and delight you."
    ),
    youtubeId: 'XVzuVXgbnwY&t=21s',
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_malaysia.webp',
  },
  'philippines': {
    description: i18n.t(
      'messages|location:Philippines:description',
      "Philippines a country of islands and smiles. With a population of over 108 million, it's the 12th most populous in the world. Its culture is a blend of indigenous, Spanish, and American influences. From the beaches of Boracay to the rice terraces of Banaue, Philippines offers a diverse range of experiences. Its economy is growing rapidly, with a focus on outsourcing, manufacturing, and tourism. Come for the adobo, stay for the warmth - Philippines is a country that will make you feel at home."
    ),
    youtubeId: 'cQ6DHRKHcFs',
    flagUrl:
      'https://storage.googleapis.com/levitate-project-management/levitate-me-static-resource/flag_country/flag_philippines.webp',
  },
};

export enum TextProjectNavigator {
  Description = 'Description',
  Timeline = 'Timeline',
  Developer = 'Developer',
  Architect = 'Architect',
  Facilities = 'Facilities',
  Signature = 'Signature',
  SignaturePackage = 'Signature Package',
};

export const GENDER_LIST = [
  {
    label: i18n.t('Male'),
    value: 'MALE',
  },
  {
    label: i18n.t('Female'),
    value: 'FEMALE',
  }
];

export const COUNTRY_NAME = [
  {
    label: 'Vietnam',
    value: 'VN',
  },
  {
    label: 'England',
    value: 'EN',
  }
];

export enum BrowserName {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Opera = 'Opera',
  Edge = 'Edge',
};

export enum PlatformName {
  Mac = 'Mac',
  Win = 'Win',
}

export enum DomId {
  headerTop = 'headerTop',
  listProjectId = 'listProjectId',
  nearbyProjectId = 'nearbyProjectId',
  streetView = 'streetView',
}

export const MAX_TOTAL_BUDGET = {
  value: 400000,
  currency: ECurrency.Usd,
};
