import { OrderType } from 'constants/orderConstants';
import ProductItem from './productItem';
import { ProjectStatus } from './project';
import { Obj } from 'constants/types';
import { OrderStatus } from './order';

export class DesignHome {
  activeSellBareshell?: boolean;
  username?: string;
  blockId?: number;
  blockName?: string;
  segmentId?: number;
  floorId?: number;
  floorName?: string;
  planId?: number;
  collectionId?: number;
  personalityId?: number;
  layoutId?: number;
  productItems?: ProductItem[];
  projectId?: number;
  projectName?: string;
  layoutName?: string;
  locationId?: number;
  orderType?: OrderType;
  orderStatus?: OrderStatus;
  status?: ProjectStatus;
  orderId?: number;
  totalBlocks?: number;
  totalFloors?: number;
  extraPrice?: number;
  staticDate?: string;

  constructor({
    activeSellBareshell,
    username,
    blockId,
    blockName,
    segmentId,
    floorId,
    collectionId,
    planId,
    personalityId,
    layoutId,
    productItems,
    projectId,
    projectName,
    floorName,
    layoutName,
    locationId,
    orderType,
    status,
    orderId,
    totalBlocks,
    totalFloors,
    extraPrice,
    orderStatus,
    staticDate,
  }: DesignHome) {
    if (activeSellBareshell !== undefined)
      this.activeSellBareshell = activeSellBareshell;
    if (username !== undefined) this.username = username;
    if (blockId !== undefined) this.blockId = blockId;
    if (blockName !== undefined) this.blockName = blockName;
    if (segmentId !== undefined) this.segmentId = segmentId;
    if (floorId !== undefined) this.floorId = floorId;
    if (collectionId !== undefined) this.collectionId = collectionId;
    if (planId !== undefined) this.planId = planId;
    if (personalityId !== undefined) this.personalityId = personalityId;
    if (layoutId !== undefined) this.layoutId = layoutId;
    if (productItems !== undefined) this.productItems = productItems;
    if (projectId !== undefined) this.projectId = projectId;
    if (projectName !== undefined) this.projectName = projectName;
    if (layoutName !== undefined) this.layoutName = layoutName;
    if (floorName !== undefined) this.floorName = floorName;
    if (locationId !== undefined) this.locationId = locationId;
    if (orderType !== undefined) this.orderType = orderType;
    if (status !== undefined) this.status = status;
    if (orderId !== undefined) this.orderId = orderId;
    if (totalBlocks !== undefined) this.totalBlocks = totalBlocks;
    if (totalFloors !== undefined) this.totalFloors = totalFloors;
    if (extraPrice !== undefined) this.extraPrice = extraPrice;
    if (orderStatus !== undefined) this.orderStatus = orderStatus;
    if (staticDate !== undefined) this.staticDate = staticDate;
  
    return this;
  }
};

export class ProjectInfo {
  projectName?: string;
  logoUri?: string;
  viewStar?: number;
  lat?: number;
  lng?: number;

  constructor({
    projectName,
    logoUri,
    viewStar,
    lat,
    lng,
  }: Obj) {
    if (projectName !== undefined) this.projectName = projectName;
    if (logoUri !== undefined) this.logoUri = logoUri;
    if (viewStar !== undefined) this.viewStar = viewStar;
    if (lat !== undefined) this.lat = lat;
    if (lng !== undefined) this.lng = lng;

    return this;
  }
};
