import moment, { Moment } from 'moment';
import BaseModel from './baseModel';
import { Position } from 'geojson';
import { Obj } from 'constants/types';
import { GalleryPhoto } from './commonTypes';
import { ProjectStatus } from 'model/project';
import { ELocales, TCurrencyObject } from 'constants/currency';
import { IPaymentSchedule } from 'constants/mortgage';
import { BASE_DATE_FORMAT } from 'constants/dateTimeConstants';
import { OrderType } from 'constants/orderConstants';
import { FloorSegment } from './floorSegment';
import { FinancialPackage } from './financialPlan';

export type OrderProduct = {
  placeHolderId: number;
  productChooseId: number;
  quantity: number;
}

export enum OrderStep {
  Ordering = 'Ordering',
  Ordered = 'Ordered',
}

// createdAt: "2022-06-15T02:41:55.989313Z"
// customer: {id: 36, name: "Mighty Dev", phoneNumber: "+84999111233", email: "might.dev.33@gmal.com",…}
// saleAgent: {id: 17, name: "Twin Account", phoneNumber: "2345678765", email: "lev_twin@integration.vt",…}

export enum OrderDocumentType {
  Completed = 'document.completed',
}

export enum OrderStatus {
  New = 'NEW',
  Pending = 'PENDING',
  PreOrdered = 'PRE_ORDERED',
  Booked = 'BOOKED',
  Bought = 'BOUGHT',
  Cancelled = 'CANCELED',
  Any = 'ANY',
};

export enum PandadocOrderType {
  RESERVATION_AGREEMENT = 'RESERVATION_AGREEMENT',
  SALES_PURCHASE_AGREEMENT = 'SALES_PURCHASE_AGREEMENT',
}

export enum PandadocOrderStatus {
  DRAFT = 'DRAFT',
  UPLOADED = 'UPLOADED',
  
  SENT = 'SENT',
  VIEWED = 'VIEWED',

  WAITING_PAY = 'WAITING_PAY',
  PAID = 'PAID',

  COMPLETED = 'COMPLETED',
}

export const OrderStatusCase: any = {
  [OrderStatus.Booked]: 'Booked',
  [OrderStatus.Bought]: 'Purchased',
  [OrderStatus.Pending]: 'Pending',
  [OrderStatus.Cancelled]: 'Canceled',
  [OrderStatus.PreOrdered]: 'Joined Waiting List',
}

export const OrderStatusColor: any = {
  [OrderStatus.Booked]: 'text-purchased',
  [OrderStatus.Bought]: 'text-purchased',
  [OrderStatus.Pending]: 'text-pending',
  [OrderStatus.Cancelled]: 'text-canceled',
  [OrderStatus.PreOrdered]: 'text-preOrder',
}

export enum PaymentScheduleDescription {
  Reservation = 'Reservation Deposit',
  SignAgreement = 'Sign S&P Agreement',
  DateLayout = 'At cut-off date layout changes',
  DateDesign = 'At cut-off date design changes',
  UponHandover = 'Upon Handover',
}

export type OrderCustomer = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  phoneNumber?: string;
  saleAgentId?: string;
  status?: string;
  umsUserId?: string;
}

export type OrderDocumentPandaDoc = {
  documentPandaDocId?: string;
  documentDownloadUri?: string;
  documentShareLink?: string;
  languageCode?: ELocales;
  orderId?: number;
  status?: PandadocOrderStatus;
  type?: PandadocOrderType;
  completedDate?: string;
  documentPayment?: number;
}

export type PaymentScheduleItem = {
  createdBy?: any;
  days?: any;
  description?: PaymentScheduleDescription;
  dueDateType?: any;
  fixedAmount?: any;
  id?: number;
  paymentScheduleItemId?: any;
  percentage?: number;
  staticDate?: string;
  termNo?: string;
}

class Order extends BaseModel<Order> {
  id?: number;
  
  projectId?: number;
  projectName?: string;
  
  blockName?: string;
  
  floorId?: number;
  floorName?: string;
  floorPlanImageUrl?: string;
  
  segmentId?: number;
  segmentName?: string;
  segmentCoordinates?: Position[][];
  
  layoutId?: number;
  layoutName?: string;
  layoutDisplayName?: string;
  
  orderItems?: OrderProduct[];
  galleryPhotos?: GalleryPhoto[];
  
  bareShellPrice?: number;
  fitOutPrice?: number;
  furniturePrice?: number;
  blockId?: number;
  floorPlanId?: number;
  
  designChangeCutOffDate?: Moment;
  layoutChangeCutOffDate?: Moment;
  status?: OrderStatus;
  
  contractPdfUrl?: string;
  brochurePdfUrl?: string;
  projectStatus?: ProjectStatus;
  isMortgage?: boolean;
  currency?: TCurrencyObject;
  paymentSchedule?: IPaymentSchedule | null;
  vat?: number;
  discount?: number;
  furniturePriceDiscount?: number;
  createdAt?: string;

  totalPrice?: string;
  totalVat?: string;
  totalVatPrice?: string;
  type?: OrderType;
  floorSegment?: FloorSegment;
  paymentScheduleItem?: PaymentScheduleItem[];
  financialPackage?: FinancialPackage;
  customer?: OrderCustomer;
  personalPropertiesId?: number;
  documentPandaDoc?: OrderDocumentPandaDoc[];
  
  static _attrNames: (keyof Order)[] = [
    'id', 'projectId', 'projectName', 'blockName', 'floorId', 'floorName',
    'floorPlanImageUrl', 'segmentId', 'segmentCoordinates', 'layoutId', 'layoutName',
    'orderItems', 'bareShellPrice', 'fitOutPrice', 'furniturePrice', 'status', 'isMortgage', 'createdAt',
  ];
  
  protected _getAttrNames(): string[] {
    return Order._attrNames;
  }
  
  constructor(attrs?: Partial<Order>) {
    super();
    
    if (attrs) {
      this.assign(attrs);
    }
  }

  get coverImageUrl() {
    return this.galleryPhotos?.[0]?.photoUri || undefined;
  }
  
  get canChangeDesign() {
    if (this.status === OrderStatus.Cancelled) return false;
    
    if (!this.designChangeCutOffDate) return true;
    
    return this.designChangeCutOffDate.isSameOrAfter(moment(), 'day')
  }
  
  get canChangeLayout() {
    if (this.status === OrderStatus.Cancelled) return false;
    
    if (!this.layoutChangeCutOffDate) return true;
    
    return this.layoutChangeCutOffDate.isSameOrAfter(moment(), 'day')
  }
  
  fromPayload({
    id,
    projectId,
    projectName,
    blockName,
    floorId,
    floorName,
    floorPlanImageUrl,
    segmentId,
    segmentCoordinates,
    layoutId,
    layoutName,
    layoutDisplayName,
    orderItems,
    gallery,
    bareShellPrice,
    fitOutPrice,
    furniturePrice,
    status,
    segmentName,
    blockId,
    floorPlanId,
    paymentSchedule,
  
    contractPdfUrl,
    brochurePdfUrl,
    projectStatus,
    isMortgage,
    price,
    paymentScheduleResults,
    vat,
    discount,
    furniturePriceDiscount,
    createdAt,

    totalPrice,
    totalVat,
    totalVatPrice,
    type,
    floorSegment,
    paymentScheduleItem,
    financialPackage,
    customer,
    personalPropertiesId,
    documentPandaDoc,
  }: Obj): this {
    if (id !== undefined) this.id = id;
    if (projectId !== undefined) this.projectId = projectId;
    if (projectName !== undefined) this.projectName = projectName;
    if (blockName !== undefined) this.blockName = blockName;
    if (floorId !== undefined) this.floorId = floorId;
    if (floorName !== undefined) this.floorName = floorName;
    if (floorPlanImageUrl !== undefined) this.floorPlanImageUrl = floorPlanImageUrl;
    if (segmentId !== undefined) this.segmentId = segmentId;
    if (segmentCoordinates !== undefined)
      this.segmentCoordinates = JSON.parse(segmentCoordinates);
    if (layoutId !== undefined) this.layoutId = layoutId;
    if (layoutName !== undefined) this.layoutName = layoutName;
    if (layoutDisplayName !== undefined) this.layoutDisplayName = layoutDisplayName;
    if (orderItems !== undefined) this.orderItems = orderItems;
    if (gallery?.photos !== undefined) this.galleryPhotos = gallery?.photos;
    if (bareShellPrice !== undefined) this.bareShellPrice = +bareShellPrice;
    if (fitOutPrice !== undefined) this.fitOutPrice = +fitOutPrice;
    if (furniturePrice !== undefined) this.furniturePrice = +furniturePrice;
    if (status !== undefined) this.status = status;
    if (segmentName !== undefined) this.segmentName = segmentName;
    if (floorPlanId !== undefined) this.floorPlanId = floorPlanId;
    if (blockId !== undefined) this.blockId = blockId;
    if (paymentSchedule?.designChangeCutOffDate)
      this.designChangeCutOffDate = moment(paymentSchedule?.designChangeCutOffDate, BASE_DATE_FORMAT).utc(true);
    if (paymentSchedule?.layoutChangeCutOffDate)
      this.layoutChangeCutOffDate = moment(paymentSchedule?.layoutChangeCutOffDate, BASE_DATE_FORMAT).utc(true);
    
    if (contractPdfUrl !== undefined) this.contractPdfUrl = contractPdfUrl;
    if (brochurePdfUrl !== undefined) this.brochurePdfUrl = brochurePdfUrl;
    if (projectStatus !== undefined) this.projectStatus = projectStatus;
    if (price !== undefined) this.currency = price.currency;
    if (paymentScheduleResults !== undefined) this.paymentSchedule = paymentScheduleResults?.[0] || null;
    if (vat !== undefined) this.vat = vat * 1.0;
    if (isMortgage !== undefined) this.isMortgage = isMortgage;
    if (discount !== undefined) this.discount = discount;
    if (furniturePriceDiscount !== undefined) this.furniturePriceDiscount = furniturePriceDiscount;
    if (createdAt !== undefined) this.createdAt = createdAt;

    if (totalPrice !== undefined) this.totalPrice = totalPrice;
    if (totalVat !== undefined) this.totalVat = totalVat;
    if (totalVatPrice !== undefined) this.totalVatPrice = totalVatPrice;
    if (type !== undefined) this.type = type;
    if (floorSegment !== undefined) this.floorSegment = floorSegment;
    if (paymentScheduleItem !== undefined) this.paymentScheduleItem = paymentScheduleItem;
    if (financialPackage !== undefined) this.financialPackage = financialPackage;
    if (customer !== undefined) this.customer = customer;
    if (personalPropertiesId !== undefined) this.personalPropertiesId = personalPropertiesId;
    if (documentPandaDoc !== undefined) this.documentPandaDoc = documentPandaDoc;
    
    return this;
  }
  
  toPayload(): Obj {
    return {
      projectId: this.projectId,
      segmentId: this.segmentId,
      floorId: this.floorId,
      layoutId: this.layoutId,
      orderItems: this.orderItems,
      isMortgage: this.isMortgage
    };
  }
}

export default Order;
